import React from 'react';
import { Row, Col, Spin, Button, Modal, Icon, Popover, message } from 'antd';
import dynamic from 'next/dynamic';
import Router from 'next/router';
import Notice from './Notice';
import { get, post } from '$ACTIONS/TlcRequest';
import { ApiPort } from '$ACTIONS/TLCAPI';
import { Cookie as CookieUtil, numberformatter } from '$ACTIONS/util';
import { GetAllBalance } from '$DATA/wallet';
import { getQueryVariable } from '$ACTIONS/helper';
import { Cookie } from '$ACTIONS/helper';
import { connect, Provider } from 'react-redux';
import { promotionActions } from '$STORE/promotionSlice';
import { userCenterActions } from '$STORE/userCenterSlice';
import { setResetGameHideAction } from "$STORE/thunk/gameThunk";
import { getMemberInfoAction } from "$STORE/thunk/memberThunk";
import { store } from '$STORE/store';
import classNames from 'classnames';
import NewMemberPromotion from "@/UserSign/NewMemPromoModal";
import RealNameVerify from "@/Verification/RealNameVerify";
import Loading from "@/Wallet/Loading";

// Modal加载状态组件
const ModalLoading = <Spin spinning={true} size="large" tip="กำลังโหลด กรุณารอสักครู่..." />; // 加载中，请稍后...
// 财务管理
const DynamicWallet = dynamic(import('@/Wallet'), {
	loading: () => ModalLoading,
	ssr: true
});
// 安全系统升级
const DynamicOtpPopUp = dynamic(import('@/OTP/OtpPopUp'), {
	ssr: false
});

class HasLogged extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showType: -1, // -1 初始化 0.1 隐藏个人中心 0.2 隐藏余额 1 个人中心简介 2 余额
			showTypePosition: { x: 110, y: 176 },
			isModalLoad: false,
			loadingText: "กำลังโหลด กรุณารอสักครู่...",
			modalTabKey: {}, // showDailog的公用属性对象
			balanceLoading: true,
			walletVisible: false,
			balanceList: [],
			userName: '', // 会员名称
			helloString: '', // 问候时间段
			token: '',
			messageIsRead: false, // 是否有未读消息
			logoutType: '', // 8号商城登出问题
			otpVisible: false, // otp 驗證匡
			kindOfVerification: '', //是otp验证还是otp+密码验证
			isLoadOtpModal: false,
			countBalance: 0,
			isNewMemberPromotionVisible: false,
			realnameVisible: false, // 真實姓名驗證
			isRefreshMemberInfo: false, // 會員資料刷新中
		};

		this.otpModal = this.otpModal.bind(this); // otp modal
		this.showModal = this.showModal.bind(this); // showModal公用方法
		this.getBalance = this.getBalance.bind(this); // 获取余额
		this.showSimple = this.showSimple.bind(this); // 显示个人或者余额下拉
		this.hideSimple = this.hideSimple.bind(this); // 隐藏个人或者余额下拉
		this.closeWallet = this.closeWallet.bind(this); // 关闭钱包弹层
		// this.getHelloTime = this.getHelloTime.bind(this); // 获取问候语时间段
		this.globalExit = this.globalExit.bind(this); // 退出方法
		this.globalBlackListExit = this.globalBlackListExit.bind(this);
		this.setGlobalMemberInfo = this.setGlobalMemberInfo.bind(this);
		this.setHasUnRead = this.setHasUnRead.bind(this); // 设置（Header弹窗、UserCenter）是否有未读消息
		this.checkHasReadMessage = this.checkHasReadMessage.bind(this); // 判断是否有未读消息
		this.currentModalKey = ''; // 当前显示的ModalKey
		this.delayTimer = null; // 个人或者余额下拉延迟关闭timer（防止过度闪动，高频率打开关闭！）
		this.messageIntervalTimer = null;
		this.controlExit = false; // 控制請求logout時,勿重複請求，如重複登入引起的強制登出
		this.correctMemberInfo = this.correctMemberInfo.bind(this); // 更新会员信息
		global.globalExit = this.globalExit; // 全局化退出方法
		global.globalBlackListExit = this.globalBlackListExit; //黑名單專用退出
		global.setGlobalMemberInfo = this.setGlobalMemberInfo; // 全局更新会员信息（HasLogged&&UserCenter内部的memberInfo）
	}
	componentDidMount() {
		if (getQueryVariable('redirectToken')) return;
		// this.getHelloTime();
		this.getBalance();
		global.showDialog = this.showModal;
		/* 
            HasLogged => 設置此參數來判斷 showModal 已設置完成，避免開啟遊戲確認餘額時 global.showDialog 為空
            NotLogged => 直接設為 true
        */
		this.props.setIsWalletSetupComplete && this.props.setIsWalletSetupComplete(true);
		this.props.onHasLoggedEvent(this.showSimple, this.hideSimple);

		if (localStorage.getItem('memberInfo')) {
			const memberdate = JSON.parse(localStorage.getItem('memberInfo'));
			this.setState({ userName: memberdate.userName });

			if (!localStorage.getItem('login-otp') && memberdate.isLoginOTP == true) {
				//OTP流程
				this.setState({
					isLoadOtpModal: true,
					otpVisible: true,
					kindOfVerification: 'OTP'
				});
			}
			if (!JSON.parse(localStorage.getItem('login-otpPwd')) && memberdate.revalidate) {
				//OTP流程 + 重置密码流程
				this.setState({
					isLoadOtpModal: true,
					otpVisible: true,
					kindOfVerification: 'Revalidate'
				});
			}
		}

		if(this.props.isNewMemFirstLogin){
			this.setState({ isNewMemberPromotionVisible:true })
		}

		this.props.getMemberInfo((res) => {
			this.setGlobalMemberInfo(res);
		}, true);

		// 传递设置已读未读状态的方法出去
		this.props.setCircleHasUnRead &&
			this.props.setCircleHasUnRead((status) => {
				this.props.setMessageHasUnRead(status);
				this.setState({ messageIsRead: status });
			});

		// 判断是否有未读消息
		this.checkHasReadMessage();
		this.messageIntervalTimer = setInterval(this.checkHasReadMessage, 360000);

		// 默认呼出组件（开发需要）
		// this.showModal({ key: 'wallet:{"type": "deposit", "currentPayValue": "SR"}' })
		// key: 'wallet:{"type": "deposit", "currentPayValue": "LB", "targetAccount": "SB", "bonusId": 103502}'
		this.checkIsSafeHouse();
	}
	componentWillUnmount() {
		global.showDialog = function() {};
		clearTimeout(this.delayTimer);
		clearInterval(this.messageIntervalTimer);
	}

	setGlobalMemberInfo(res) {
		console.log('获取会员详情的数据-------------->', res);
		//登录OTP PASS 进行下面Ref流程
		!res.isLoginOTP && this.props.ReferreeTaskStatus();
		// 同步HasHeader MemberInfo 到子元素同时设置更新HasHeader MemberInfo的方法
		this.props.setUserCenterMemberInfo &&
			this.props.setUserCenterMemberInfo(
				res,
				(v) => {},
				this.getBalance
			);
	}
	checkHasReadMessage() {
		get(ApiPort.Announcements + '&messageTypeOptionID=0&pageSize=1&pageIndex=1').then((res) => {
			if (res?.result?.totalUnreadCount) {
                this.setHasUnRead(true);
            } else {
                get(
                    ApiPort.InboxMessages +
                        "&MessageTypeID=2&messageTypeOptionID=0&pageSize=1&pageIndex=1"
                ).then((res) => {
                    if (res?.result?.totalUnreadCount) {
                        this.setHasUnRead(true);
                    } else {
                        get(
                            ApiPort.InboxMessages +
                                "&MessageTypeID=1&messageTypeOptionID=0&pageSize=1&pageIndex=1"
                        ).then((res) => {
                            if (res?.result?.totalUnreadCount) {
                                this.setHasUnRead(true);
                            }
                        });
                    }
                });
            }
		});
	}
	setHasUnRead(status) {
		this.props.setUsercenterCircle && this.props.setUsercenterCircle(status);
		this.props.setMessageHasUnRead(status);
		this.setState({ messageIsRead: status });
	}
	// getHelloTime() {
	// 	const hour = new Date().getHours();

	// 	this.setState({
	// 		helloString:
	// 			hour < 6
	// 				? '凌晨'
	// 				: hour < 9 ? '早上' : hour < 12 ? '上午' : hour < 14 ? '中午' : hour < 19 ? '下午' : hour < 24 ? '晚上' : ''
	// 	});
	// }
	getBalance(call, setLoadingStatus) {
		GetAllBalance((res) => {
			if (res?.isSuccess && res?.result) {
				console.log('钱包列表------->', res);

				res.result.map(function(item, index) {
					window[item.name] = item.balance;
				});
				const totalBal = res.result?.find((item)=>item.name === "TotalBal")?.balance || 0;
				this.props.setUserCenterMoney &&
					this.props.setUserCenterMoney({
						balanceList: res.result,
						mainMoney: totalBal
					});
				this.props.setUserAllBalance(totalBal);
				this.setState({
					balanceList: res.result,
					countBalance: totalBal,
				});
			} else {
				message.error(res.errors && res.errors[0].message);
			}
			typeof call === 'function' && call(res);
		}, (status) => {
			this.setState({ balanceLoading: status });
			typeof setLoadingStatus === 'function' && setLoadingStatus(status);
		});
	}
	// 关闭钱包弹层
	closeWallet(action) {
        this.setState({ walletVisible: false });

        //在遊戲頁面關閉錢包
        if (action && action === "skipRealName") {
            return;
        }
        this.props.setOpenGame && this.props.setOpenGame();
        // typeof global.openLearnDialog === 'function' && global.openLearnDialog();
    }
	// 呼出组件（所有弹出层窗口都可公用这个函数，需设置一级State和二级State，只需传入对应State即可！）
	showModal({ key }) {
		if (typeof key !== 'string') return null;
		const keySpacer = key.indexOf(':');
		const dialogKey = key.substring(0, keySpacer);
		const keyMap = key.substring(keySpacer + 1);
		const currentParentModalMap = typeof keyMap !== '' ? JSON.parse(keyMap) : {}; // 当前呼出窗口携带的默认属性
		this.setState({
			[dialogKey + 'Visible']: true,
			modalTabKey: currentParentModalMap
		});
	}
	showSimple(type, positionType) {
		clearTimeout(this.delayTimer);
		positionType === 'top' &&
			this.setState({
				showTypePosition: { x: type === 1 ? 177 : 51, y: 42 }
			});
		positionType === 'bottom' &&
			this.setState({
				showTypePosition: { x: type === 1 ? 110 : -8, y: 176 }
			});
		this.setState({ showType: type });
		this.props.onChangeShowType(type);
	}
	hideSimple(type) {
		if (this.state.showType === -1) {
			return;
		}
		this.delayTimer && clearTimeout(this.delayTimer);
		this.delayTimer = setTimeout(() => {
			this.setState({ showType: type });
			this.props.onChangeShowType(0);
		}, 200);
	}

	globalExit(snapExitStatus) {
		const { memberInfo } = this.props;
		const access_token = JSON.parse(localStorage.getItem('access_token'));
		const refresh_token = JSON.parse(localStorage.getItem('refresh_token'));
		let data = {
			clientId: 'FUNmuittenCN',
			clientSecret: 'muitten',
			refreshToken: refresh_token,
			accessToken: access_token,
			memberCode: memberInfo.memberCode,
			packageName: 'net.funpodium.tlc'
		};

		if (this.controlExit) return;
		const IsSnapExitStatus = typeof snapExitStatus === 'undefined';

		// IsSnapExitStatus &&
		// 	post(ApiPort.LogoutAPI, data)
		// 		.then((res) => {
		// 			// 8shop导向登录退出立即就会登录，登录会更正客服链接，所以此处如果传递function不需要调用。
		// 			get(ApiPort.GETLiveChat).then((res) => {
		// 				localStorage.setItem('serverUrl', res.url);
		// 				setTimeout(() => {
		// 					this.controlExit = false;
		// 				}, 3000);
		// 			});
		// 		})
		// 		.catch((error) => {});

		this.controlExit = true;
		localStorage.getItem('access_token') !== null && this.props.LoginExit(); // 更正会员登录状态
		Modal.destroyAll(); // 清除所有弹出层
		CookieUtil(null, null);
		// OTP time
		Cookie.Delete('login-otpemailTime');
		Cookie.Delete('login-otpphoneTime');
		Cookie.Delete('login-otpvoiceTime');
		Cookie.Delete('memberProfile-otpemailTime');
		Cookie.Delete('memberProfile-otpphoneTime');
		Cookie.Delete('memberProfile-otpvoiceTime');
		Cookie.Delete('withdrawal-otpemailTime');
		Cookie.Delete('withdrawal-otpphoneTime');
		Cookie.Delete('withdrawal-otpvoiceTime');
		Cookie.Delete('cry-otpphoneTime');
		Cookie.Delete('cry-otpvoiceTime');
		// Member LocalData
		localStorage.removeItem('affiliateUrlLM');
		localStorage.removeItem('PreferWallet');
		localStorage.removeItem('UserName');
		localStorage.removeItem("userName");
		localStorage.removeItem('memberCode');
		localStorage.removeItem("userNum");
        localStorage.removeItem("autoLogin");
        localStorage.removeItem("RestrictAccessCode");
		localStorage.removeItem('access_token');
		localStorage.removeItem('refresh_token');
		localStorage.removeItem('memberInfo');
		localStorage.removeItem('walletList');
		localStorage.removeItem('Address');
		localStorage.removeItem('OpenReferDone');
		// Bti LocalData
		localStorage.removeItem('ReferURL');
		localStorage.removeItem('APIUrl');
		localStorage.removeItem('MemberToken');
		localStorage.removeItem('login-otp');
		localStorage.removeItem('login-otpPwd');
		sessionStorage.clear();
		// 重置 redux 相關資料
		this.props.resetPromotionData();
		this.props.resetMemberData();
		this.props.resetGameHide();

		IsSnapExitStatus && message.success('คุณออกจากระบบแล้ว!'); //您已退出登录！

		// 此处为了测试退出后的一些状态时是否及时更新预留
		IsSnapExitStatus && Router.push('/');

		if (IsSnapExitStatus) {
			if (this.state.checkSafeHouse || Cookie.Get('isLoginS')) {
				window.location.href = '/th/safehouse';
				Cookie.Delete('isLoginS');
			} else {
				Cookie.Delete('isLoginS');
			}
		}
	}

	/*專門給黑名單使用的logout 不跳回首頁  */
	globalBlackListExit(snapExitStatus) {
		if (this.controlExit) return;
		const IsSnapExitStatus = typeof snapExitStatus === 'undefined';

		this.controlExit = true;
		localStorage.getItem('access_token') !== null && this.props.LoginExit(); // 更正会员登录状态
		Modal.destroyAll(); // 清除所有弹出层
		CookieUtil(null, null);
		// OTP time
		Cookie.Delete('login-otpemailTime');
		Cookie.Delete('login-otpphoneTime');
		Cookie.Delete('login-otpvoiceTime');
		Cookie.Delete('memberProfile-otpemailTime');
		Cookie.Delete('memberProfile-otpphoneTime');
		Cookie.Delete('memberProfile-otpvoiceTime');
		Cookie.Delete('withdrawal-otpemailTime');
		Cookie.Delete('withdrawal-otpphoneTime');
		Cookie.Delete('withdrawal-otpvoiceTime');
		Cookie.Delete('cry-otpphoneTime');
		Cookie.Delete('cry-otpvoiceTime');
		// Member LocalData
		localStorage.removeItem('affiliateUrlLM');
		localStorage.removeItem('PreferWallet');
		localStorage.removeItem('UserName');
		localStorage.removeItem("userName");
		localStorage.removeItem('memberCode');
		localStorage.removeItem("userNum");
        localStorage.removeItem("autoLogin");
        localStorage.removeItem("RestrictAccessCode");
		localStorage.removeItem('access_token');
		localStorage.removeItem('refresh_token');
		localStorage.removeItem('memberInfo');
		localStorage.removeItem('walletList');
		localStorage.removeItem('Address');
		localStorage.removeItem('OpenReferDone');
		// Bti LocalData
		localStorage.removeItem('ReferURL');
		localStorage.removeItem('APIUrl');
		localStorage.removeItem('MemberToken');
		localStorage.removeItem('login-otp');
		localStorage.removeItem('login-otpPwd');
		sessionStorage.clear();

		this.props.resetPromotionData();
		this.props.resetMemberData();
		this.props.resetGameHide();

		IsSnapExitStatus && message.success('คุณออกจากระบบแล้ว!'); //您已退出登录！

		if (IsSnapExitStatus) {
			if (this.state.checkSafeHouse || Cookie.Get('isLoginS')) {
				window.location.href = '/th/safehouse';
				Cookie.Delete('isLoginS');
			} else {
				Cookie.Delete('isLoginS');
			}
		}
	}

	checkIsSafeHouse = () => {
		let domain = window.location.origin;
		get(ApiPort.GETisSafeHouse + `&domain=${domain}`)
			.then((res) => {
				console.log('isSafeHouse Domain res: ', res);
				if (res.result) {
					this.setState({
						checkSafeHouse: true
					});
				}
			})
			.catch((error) => {
				console.log('isSafeHouse Domain error: ', error);
			});
	};

	Exit() {
		Modal.confirm({
			icon: null,
			centered: true,
			title: '确认退出',
			content: '确认要退出登录吗？',
			// okButtonProps: {size: "large"},
			// cancelButtonProps: {size: "large"},
			okText: '退出',
			cancelText: '取消',
			onOk: () => {
				this.globalExit();
			}
		});
	}

	otpModal(v) {
		this.setState({ otpVisible: v });
	}

	correctMemberInfo() {
		return new Promise((resolve, reject) => {
            this.setState({ isRefreshMemberInfo: true });
            this.props.getMemberInfo((res) => {
                this.setState({ isRefreshMemberInfo: false });
                resolve(res);
            }, true);
        });
    }

	render() {
		// HasLogged组件因为是在渲染之后才会render出来，所以在内部可调用dom相关事件
		const { showType, balanceList, countBalance, isNewMemberPromotionVisible, realnameVisible, isRefreshMemberInfo } = this.state;
		const { memberInfo } = this.props;
		let userPopover, walletPopover;
		console.log('memberInfo', memberInfo);
		switch (showType) {
			case -1:
				(userPopover = ''), (walletPopover = '');
				break;
			case 1:
				userPopover = 'show';
				break;
			case 2:
				walletPopover = 'show';
				break;
			case 0.1:
				userPopover = 'hide';
				break;
			case 0.2:
				walletPopover = 'hide';
				break;
			default:
				break;
		}
		return (
			<React.Fragment>
				<div className="common-distance-wrap">
					<div
						className={`common-distance tlc-sign-header ${this.props.smallHeader === 'zoom-out' && 'hidden'}`}
					>
						<Row>
							<Col span={12} className="tlc-notice-wrapper">
								<Notice propsData={this.props} isGamePage={this.props.isGamePage} />
							</Col>
							<Col span={12} className="tlc-sign">
								<div className="login-wrap">
									<div className="good-greeting">
										{/* {this.state.helloString}好， */}
										สวัสดี，
										{this.state.userName || memberInfo?.userName}
									</div>
									<div className="Right-Login-Action">
										<div className="input-wrap">
											<div
												className={`user-center-btn${showType === 1 ? ' active' : ''}`}
												onMouseEnter={() => this.showSimple(1, 'bottom')}
												onMouseLeave={() => this.hideSimple(0.1)}
											>
												<span>ข้อมูลบัญชี</span>
												{/* 个人中心 */}
												<i
													className={`tlc-sprite user-message ${this.state.messageIsRead
														? 'remind-circle'
														: ''}`}
												/>
												<Icon type="caret-down" />
											</div>
											<div
												className={`wallet-center-btn${showType === 2 ? ' active' : ''}`}
												onMouseEnter={() => this.showSimple(2, 'bottom')}
												onMouseLeave={() => this.hideSimple(0.2)}
											>
												<span>฿ </span>
												<span>{numberformatter(countBalance)}</span>
												<Icon type="caret-down" />
											</div>
										</div>
										<div className="BtnList">
											<div
												className="deposit-btn btnYellow "
												onClick={() => {
													this.showModal({
														key: 'wallet:{"type": "deposit"}'
													});

													Pushgtagdata('Home', 'Go to Deposit', 'Home_C_Deposit');
												}}
											>
												ฝากเงิน
												{/* 存款 */}
											</div>
											<div
												className="transfer-btn btnBlue "
												onClick={() => {
													this.showModal({
														key: 'wallet:{"type": "transfer"}'
													});

													Pushgtagdata('Home', 'Go to Transfer', 'Home_C_Transfer');
												}}
											>
												โอนเงิน
												{/* 转账 */}
											</div>
											<div
												className="withdraw-btn btnPurple "
												onClick={() => {
													this.showModal({
														key: 'wallet:{"type": "withdraw"}'
													});

													Pushgtagdata('Home', 'Go to Withdrawal', 'Home_C_Withdrawal');
												}}
											>
												ถอนเงิน
												{/* 提款 */}
											</div>
										</div>
									</div>
								</div>
							</Col>
						</Row>
					</div>
				</div>
				{/* Header头部弹窗 */}
				<div
					className={`common-distance-wrap header-popover-wrap`}
				>
					<div className="common-distance">
						<div
							style={{
								right: this.state.showTypePosition.x,
								top: this.state.showTypePosition.y
							}}
							className={`header-popover ${userPopover}`}
							onMouseEnter={() => this.showSimple(1)}
							onMouseLeave={() => this.hideSimple(0.1)}
						>
							<div className="header-popover-content">
								<div className="header-popover-arrow" />
								<div className="header-popover-inner">
									<Spin spinning={isRefreshMemberInfo}>
										<div className="header-popover-inner-title user-title-wrap">
											<div className="inline-block">
												<div className="user-picture-wrap">
													<img src="/th/img/home/part/head-2.png" alt="user" />
												</div>
											</div>
											<div className="inline-block">
												<h4> {this.state.userName || memberInfo?.userName}</h4>
												<div className="user-info-thumb">
													<div className="inline-block">
														<i
															className={`tlc-sprite member-grade _${memberInfo?.levelName !== 'เงิน' //普通会员
																? '1'
																: '2'}`}
														/>
														<span>{memberInfo?.levelName}</span>
													</div>
													<span className="inline-block">&nbsp;</span>
													<div className="inline-block">
														<i
															className={`tlc-sprite user-email ${memberInfo?.isVerifiedEmail?.[1] && 'curr'}`}
														/>
														<i
															className={`tlc-sprite user-phone ${memberInfo?.isVerifiedPhone?.[1] && 'curr'}`}
														/>
													</div>
												</div>
											</div>
											<Button
												ghost
												className="user-exit"
												onClick={() => {
													//  this.Exit();
													Pushgtagdata(
														"Home_TopNav",
														"Trigger Logout",
														"Home_TopNav_C_Logout"
													);
													this.globalExit();
												}}
											>
												ออกจากระบบ
												{/* 退出 */}
											</Button>
										</div>
										<ul className="header-popover-inner-content headerHoverBox">
											<li
												className="userinfo"
												onClick={() => {
													Pushgtagdata(
														"Home_TopNav",
														"Edit Profile",
														"Home_TopNav_C_EditPersonalInfo"
													);
													this.props.goUserCenter('account-info');
												}}
											>
												<i className="tlc-sprite user-info" />
												ข้อมูลบัญชี
												{/* 账户资料 */}
											</li>
											<li
												className={`message${this.state.messageIsRead ? ' remind-circle' : ''}`}
												onClick={() => {
													Pushgtagdata(
														"Home_TopNav",
														"View Message",
														"Home_TopNav_C_Notification"
													);
													this.props.goUserCenter('notification');
												}}
											>
												<i className="tlc-sprite message-center" />
												แจ้งเตือน
												{/* 通知中心 */}
											</li>
											<li
												className="records"
												onClick={() => {
													Pushgtagdata(
														"Home_TopNav",
														"Go to Transaction Record",
														"Home_TopNav_C_TransactionRecord"
													);
													this.props.goUserCenter('transaction-record');
												}}
											>
												<i className="tlc-sprite record" />
												ประวัติ
												{/* 交易记录 */}
											</li>
											<li
												className="bankaccount"
												onClick={() => {
													Pushgtagdata(
														"Home_TopNav",
														"Go to Account Management",
														"Home_TopNav_C_AccountManagement"
													);
													if (!memberInfo?.firstName) {
														this.setState({
															realnameVisible: true,
														});
														return;
													}
													this.props.goUserCenter('bank-account');
												}}
											>
												<i className="tlc-sprite bank-user" />
												ข้อมูลธนาคาร
												{/* 银行信息 */}
											</li>
											<li
												className="mypromotion"
												onClick={() => {
													Pushgtagdata(
														"Home_TopNav",
														"Go to Daily Deal",
														"Home_TopNav_C_DailyDeal"
													);
													this.props.changeTab('2');
													Router.push("/promotions", "/โปรโมชั่น");
												}}
											>
												<i className="tlc-sprite my-promotion" />
												โบนัสของฉัน
												{/* 我的优惠 */}
											</li>
											<li
												className="betrecords"
												onClick={() => {
													Pushgtagdata(
														"Home_TopNav",
														"Go to Bet Record",
														"Home_TopNav_C_BetRecord"
													);
													this.props.goUserCenter('betting-record');
												}}
											>
												<i className="tlc-sprite bet-record" />
												ประวัติการเดิมพัน
												{/* 投注记录 */}
											</li>
											<li
												className="mybonus"
												onClick={() => {
													Pushgtagdata(
														"Home_TopNav",
														"Go to Rebate",
														"Home_TopNav_C_Rebate"
													);
													this.props.changeTab('3');
													Router.push("/promotions", "/โปรโมชั่น");
												}}
											>
												<i className="tlc-sprite my-bonus" />
												เงินคืน
												{/* 我的返水 */}
											</li>
										</ul>
									</Spin>
								</div>
							</div>
						</div>
						<div
							style={{
								right: this.state.showTypePosition.x,
								top: this.state.showTypePosition.y
							}}
							className={`header-popover wallet ${walletPopover}`}
							onMouseEnter={() => this.showSimple(2)}
							onMouseLeave={() => this.hideSimple(0.2)}
						>
							<div className="header-popover-content">
								<div className={`header-popover-arrow${this.state.balanceLoading ? ' loading' : ''}`} />
								<div className="header-popover-inner">
									<Spin spinning={this.state.balanceLoading}>
										<div className="header-popover-inner-title">
											<div className="inline-block">ยอดเงินรวม</div>
											{/* 总余额 */}
											<div className="inline-block balance-main">
												฿ {numberformatter(countBalance)}
											</div>
											<div
												style={{
													position: 'absolute',
													right: '20px'
												}}
												className="inline-block"
												onClick={() => {
													Pushgtagdata(
														"Home_TopNav",
														"Refresh Balance",
														"Home_TopNav_C_RefreshBalance"
													);
													this.getBalance();
												}}
											>
												<Icon type="reload" />
											</div>
										</div>
										<ul className="header-popover-inner-content">
											{balanceList && balanceList.length ? (
												balanceList.map((val, index) => {
													if (val.category === 'TotalBal') return null;
													return (
														<li
															key={index}
															className={classNames({
																UnderMaintenance: val.state == 'UnderMaintenance'
															})}
														>
															<span>
																<span className="localizedName">
																	{val.localizedName}
																</span>
																{/* {val.name === 'SB' ? (
																	<Popover
																		overlayStyle={{
																			zIndex: 1000
																		}}
																		align={{
																			offset: [ -4, 0 ]
																		}}
																		placement="bottomLeft"
																		overlayClassName="popover-dark"
																		content={
																			<div
																				style={{
																					textAlign: 'center',
																					width: '15rem'
																				}}
																			>
																				包含 V2虚拟体育, 沙巴体育, BTI 体育, IM 体育和电竞
																			</div>
																		}
																		title=""
																		trigger="hover"
																	>
																		<span className="header-popover-inner-tip pointer">
																			<img
																				style={{
																					paddingBottom: '0.2rem'
																				}}
																				src={`/th/img/home/icon/icon-question.svg`}
																			/>
																		</span>
																	</Popover>
																) : null}
																{val.name === 'P2P' ? (
																	<Popover
																		overlayStyle={{
																			zIndex: 1000
																		}}
																		align={{
																			offset: [ -4, 0 ]
																		}}
																		placement="bottomLeft"
																		overlayClassName="popover-dark"
																		content={
																			<div
																				style={{
																					textAlign: 'center',
																					width: '15rem'
																				}}
																			>
																				包含双赢棋牌，开元棋牌和小游戏​
																			</div>
																		}
																		title=""
																		trigger="hover"
																	>
																		<span className="header-popover-inner-tip pointer">
																			<img
																				style={{
																					paddingBottom: '0.2rem'
																				}}
																				src={`/th/img/home/icon/icon-question.svg`}
																			/>
																		</span>
																	</Popover>
																) : null} */}
															</span>
															<span className="balance">
																{
																	val.state === "UnderMaintenance"
																		? "อยู่ระหว่างปรับปรุง" //维护中
																		: val.balance
																		? "฿ " + numberformatter(val.balance)
																		: "฿ 0"
																}
															</span>
														</li>
													);
												})
											) : null}
										</ul>
									</Spin>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* 财务管理，DynamicWallet內有元件使用到redux，需加上	Provider以免抓不抓不到store */}
				{this.state.walletVisible &&
					<Modal
						title="ธนาคาร" //钱包
						footer={null}
						maskClosable={!this.state.isModalLoad}
						keyboard={!this.state.isModalLoad}
						width={750}
						className="wallet-modal"
						visible={this.state.walletVisible}
						onCancel={this.closeWallet}
						destroyOnClose={true}
						closeIcon={<img src={'/th/img/wallet/icon-close.svg'} style={{ width: '18px', height: '18px' }} />}
					>
						<Provider store={store}>
							<Loading visible={this.state.isModalLoad} loadingText={this.state.loadingText || "กำลังโหลด กรุณารอสักครู่..."} />
							{/* 加载中，请稍后... */}
							{this.state.modalTabKey !== '' ? (
								<DynamicWallet
									dialogTabKey={this.state.modalTabKey}
									getBalance={this.getBalance}
									balanceList={this.state.balanceList}
									onCancel={this.closeWallet}
									visible={this.state.walletVisible}
									goUserCenter={this.props.goUserCenter}
									setModalTabKey={(v, run) =>
										this.setState({ modalTabKey: v }, () => {
											typeof run === 'function' && run();
										})}
									setLoading={(v, str) => this.setState({ isModalLoad: v, loadingText: str})}
									GetThroughoutVerification={() => {
										this.props.GetThroughoutVerification();
									}}
									isGamePage={this.props.isGamePage}
								/>
							) : null}
						</Provider>
					</Modal>
				}

				{this.state.isLoadOtpModal == true ? (
					<DynamicOtpPopUp
						otpVisible={this.state.otpVisible} // 彈窗顯示
						otpModal={(v) => {
							this.otpModal(v);
							this.props.getMemberInfo((res) => {
								this.setGlobalMemberInfo(res);
							}, true);
						}} // 彈窗控制
						otpParam={`login-otp`} // 類別
						memberInfo={memberInfo} // 會員信息
						kindOfVerification={this.state.kindOfVerification}
						GetThroughoutVerification={() => {
							this.props.GetThroughoutVerification();
						}}
					/>
				) : null}

				{/* 新會員Promotion彈窗，僅註冊後首次登入出現 */}
                <NewMemberPromotion
                    visible={isNewMemberPromotionVisible}
                    onClose={() => {
						this.props.setMemberFirstLogin(false);
                        this.setState({ isNewMemberPromotionVisible: false });
                    }}
                />

				{/* 真實姓名驗證 */}
				{realnameVisible && (
                    <RealNameVerify
                        visible={realnameVisible}
                        closeModal={() => {
                            this.setState({ realnameVisible: false });
                        }}
                        correctMemberInfo={this.correctMemberInfo}
						isNext={true}
						nextStep={() => {
							this.props.goUserCenter('bank-account');
						}}
                    />
                )}
			</React.Fragment>
		);
	}
}

const mapStateToProps = function(state) {
	return {
		topTabIndex: state.promotion.topTabIndex,
		memberInfo: state.userCenter.memberInfo,
		isNewMemFirstLogin: state.userCenter.isNewMemFirstLogin,
	};
};

const mapDispatchToProps = function (dispatch) {
    return {
        changeTab: (i) => {
            dispatch(promotionActions.changeTab(i));
        },
        resetPromotionData: () => {
            dispatch(promotionActions.resetData());
        },
        resetMemberData: () => {
            dispatch(userCenterActions.resetData());
        },
		resetGameHide: () => {
            dispatch(setResetGameHideAction());
        },
        setMemberFirstLogin: (isNewMemFirstLogin = false) => {
            dispatch(userCenterActions.setMemberFirstLogin(isNewMemFirstLogin));
        },
        getMemberInfo: (callback, refresh) => {
            dispatch(getMemberInfoAction(callback, refresh));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HasLogged);

/*
 * @Author: Alan
 * @Date: 2022-06-07 07:47:26
 * @LastEditors: Alan
 * @LastEditTime: 2022-09-18 19:23:29
 * @Description:游戏标签
 * @FilePath: \Mobile\src\components\Games\Tag.js
 */
import React, { Component } from "react";
import classNames from "classnames";
class Tag extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasMore: true,
        };
    }

    componentDidMount() {}

    getStyle = (provider) => {
        switch (provider) {
            /* Slot */
            case "SPX":
                return { backgroundColor: "#000000", color: "#F8AA1A" };
            case "AMB":
                return { backgroundColor: "#309246" };
            case "JIR":
                return { backgroundColor: "#2230FF" };
            case "JIF":
                return { backgroundColor: "#FFFFFF", color: "#2230FF" };
            case "JKR":
                return { backgroundColor: "#E0AE02" };
            case "PGS":
                return { backgroundColor: "#12B2A1" };
            case "MGSQF":
            case "MGP":
                return { backgroundColor: "#2CCB8E" };
            case "PNG":
                return { backgroundColor: "#0182C3" };
            case "IMOPT":
                return { backgroundColor: "#2C3F7E" };
            case "FISHING":
                return { backgroundColor: "#00A6FF" };
            case "TG_SLOT":
                return { backgroundColor: "#F49239" };
            case "IMONET":
                return { backgroundColor: "#FFFFFF", color: "#46BF00" };
            case "HBN":
                return { backgroundColor: "#FB5D42" };
            case "NGS":
                return { backgroundColor: "#613AB2" };
            case "BNG":
                return { backgroundColor: "#19E4A4" };
            case "EVP":
                return { backgroundColor: "#1E5F95" };
            case "SWF":
                return { backgroundColor: "#6978DF" };
            case "CQG":
                return { backgroundColor: "#FFFFFF", color: "#FF7700" };
            case "TG":
                return { backgroundColor: "#f9994c" };
            case "SPG":
                return { backgroundColor: "#F30000" };
            case "BSG":
                return { backgroundColor: "#000000" };
            case "YBF":
                return { backgroundColor: "#B14221" };
            case "HSW":
                return { backgroundColor: "#5FB174" };
            case "EVOBT":
                return { backgroundColor: "#171717", color: "#FF0000" };
            case "EVORT":
                return { backgroundColor: "#D9E2DB", color: "#FF0000" };
            case "FCG":
                return { backgroundColor: "#2828B2", color: "#FF8519" };
            /* 棋牌 */
            case "YBP":
                return { backgroundColor: "#00A6FF" };
            case "JBP":
                return { backgroundColor: "#D1941B" };
            case "KYG":
                return { backgroundColor: "#E96450" };
            case "TGP":
                return { backgroundColor: "#F09A01" };
            case "KPK":
                return { backgroundColor: "#047B6C" };
            /* 真人娱乐 */
            case "YBL":
                return { backgroundColor: "#B14221" };
            case "EVO":
                return { backgroundColor: "#679DB9" };
            case "BGG":
                return { backgroundColor: "#2424B4" };
            case "AGL":
            case "AG":
                return { backgroundColor: "#F98436" };
            case "GPI":
            case "WEC":
                return { backgroundColor: "#00A6FF" };
            case "DGG":
                return { backgroundColor: "#2E2921", color: "#B09753" };
            case "NLE":
                return { backgroundColor: "#EA177A" };
            case "EBT":
                return { backgroundColor: "#2E67B1" };
            case "ABT":
                return { backgroundColor: "#B18F21" };
            case "VTG":
                return { backgroundColor: "#009A23" };
            case "WMC":
                return { backgroundColor: "#0E1544" };
            case "SAL":
                return { backgroundColor: "#14594E" };
            case "SXY":
                return { backgroundColor: "#FD3684" };
            /* 彩票 */
            case "TCG":
                return { backgroundColor: "#2F9D3C" };
            case "SLC":
                return { backgroundColor: "#0066FF" };
            /* InstantGames */
            case "SPR":
                return { backgroundColor: "#E5073A" };
            case "GLX":
                return { backgroundColor: "#0668FF" };
            default:
                return { backgroundColor: "#00A6FF" };
        }
    };
    // 暫時 hardCode 處理文字，目前 CXFUN88-5230 on hold
    getName = (provider) => {
        switch (provider) {
            // Sportbooks
            case "VTG":
                return "VTG";
            //  Slot
            case "SPX":
                return "SPX";
            case "AMB":
                return "AMB";
            case "JIR":
            case "JIF":
                return "JILI";
            case "JKR":
                return "JK";
            case "PGS":
                return "PG";
            case "MGSQF":
            case "MGP":
                return "MGS";
            case "PNG":
                return "PNG";
            case "IMOPT":
                return "PT";
            case "TG_SLOT":
                return "PP";
            case "IMONET":
                return "NET";
            case "SWF":
                return "SW";
            case "CQG":
                return "CQ9";
            case "SPG":
                return "SG";
            case "BSG":
                return "BSG";
            case "FISHING":
                return "FISHING";
            case "YBF":
                return "ZUI";
            case "TG":
                return "PP";
            case "HSW":
                return "HSW";
            case "EVOBT":
                return "BTG";
            case "EVORT":
                return "RT";
            /* 棋牌 */
            case "TGP":
                return "TGP";
            case "KPK":
                return "KP";
            case "YBP":
                return "ANG";
            case "JBP":
                return "JBP";
            case "KYG":
                return "KYG";
            /* 真人娱乐 */
            case "WEC":
                return "WE";
            case "YBL":
                return "ZUI";
            case "EVO":
                return "EVO";
            case "BGG":
                return "BG";
            case "AGL":
            case "AG":
                return "AG";
            case "GPI":
                return "FUN";
            case "DGG":
                return "DG";
            case "NLE":
                return "N2";
            case "EBT":
                return "EBT";
            case "ABT":
                return "ABT";
            case "WMC":
                return "WMC";
            case "SAL":
                return "SA";
            case "SXY":
                return "SXY";
            /* 彩票 */
            case "GPK":
                return "FUN";
            default:
                return provider;
        }
    };
    render() {
        const { provider, providerFlag } = this.props;
        return (
            <div
                className={classNames({
                    DefaultTag: true,
                })}
            >
                <span style={this.getStyle(provider)}>
                    {this.getName(provider)}
                </span>
            </div>
        );
    }
}
export default Tag;

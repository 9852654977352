const pubilcData = [
    {
        gameName: "体育竞技",
        providerName: "Sportsbook",
        color: [
            "#1C8EFF",
            "#00A7FA",
            "#2979FF",
        ] /* 0 按钮色 字体色 1，2 顶部背景图渐变色 */,
        egameNames: "FUN88 SPORT BETTING",
        egameName: "TLC SPORT BETTING",
        topbanner: "/cn/img/game/sport/products-sport.png",
        centerbanner: "/cn/img/game/sport/sport-bg-3.png",
        centertxt:
            "提供足球、篮球、网球、电竞等数十种体育项目投注盘口，业界领先赔率，每日千场滚球赛事一网打尽。提供LOL、dota2、王者荣耀、绝地求生、csgo等数十种电竞赛事投注盘口，独创多种串关玩法，电竞赛事应有尽有。",
        iconleft: "/th/img/game/icon-01.png",
        iconbonus: "/th/img/game/icon-03.png",
        iconright: "/th/img/game/icon-02.png",

        howtoplaytitle: [
            "什么是体育投注？",
            "什么叫做滚球？",
            "让分后，XXXXXX？",
            "行为限制的介绍？",
        ],
        howtoplay: [
            "什么叫做滚球 内容",
            "让分后，XXXXXX？内容",
            "行为限制的介绍？内容",
        ],
        bonus: "1",
        gameList: [
            {
                providerCode: "IPSB",
                providerName: "IM 体育",
                description:
                    "IM กีฬา \n นำเสนอการแข่งขันกีฬา \n ถ่ายทอดสด หลากหลายประเภท \n อัพเดทอัตราต่อรองตลอดเวลา", //最佳体育博彩手机版面和大型赛事直播，拥有各种足球和篮球的投注类型。
                iconLeftText: "คุณภาพดีและ \n เสถียรมากที่สุด", //最稳固的品质
                iconBonus: "1",
                iconBonusText: "คืนเงินสูงที่สุด \n ในอุตสากรรรม", //业界最高返水
                iconRightText: "เครดิตพิเศษ \n ได้รับการรับรอง", //独家授权信用
            },
            {
                providerCode: "OWS",
                providerName: "沙巴体育",
                description:
                    "SABA กีฬา \n ให้บริการเดิมพันออนไลน์ มากกว่า 20 ปี \n ด้วยฟังก์ชั่น ที่ทันสมัย และมีเสถียรภาพ", //操作界面简单，可依据喜好编排赛事，多种赔率模式。
                iconLeftText: "คุณภาพดีและ \n เสถียรมากที่สุด", //最稳固的品质
                iconBonus: "1",
                iconBonusText: "คืนเงินสูงที่สุด \n ในอุตสากรรรม", //业界最高返水
                iconRightText: "เครดิตพิเศษ \n ได้รับการรับรอง", //独家授权信用
            },
            {
                providerCode: "SBT",
                providerName: "BTI 体育",
                description:
                    "BTI กีฬา \n ผู้นำอันดับหนึ่งในการเดิมพันกีฬา \n ประเภทการเดิมพันเกมส์สดที่หลากหลาย", //25,000场滚球赛事，超过200种投注盘口，超过80种滚球盘，快速下一分钟投注。
                iconLeftText: "คุณภาพดีและ \n เสถียรมากที่สุด", //最稳固的品质
                iconBonus: "1",
                iconBonusText: "คืนเงินสูงที่สุด \n ในอุตสากรรรม", //业界最高返水
                iconRightText: "เครดิตพิเศษ \n ได้รับการรับรอง", //独家授权信用
            },
            {
                providerCode: "VTG",
                providerName: "V2 体育",
                description:
                    "V2 กีฬา \n การเดิมพันกีฬาเสมือนจริง \n ด้วยเทคโนโลยีล้ำสมัยเล่นได้ \n ทุกที่ ทุกเวลา พร้อมลุ้นแจ็คพอต​", //独家赛马/赛狗/拳击等多种赛事，梦幻对决实时开赛，秒下注秒开奖！还有轮盘/彩票等数字游戏随心选择！
                iconLeftText: "คุณภาพดีและ \n เสถียรมากที่สุด", //最稳固的品质
                iconBonus: "1",
                iconBonusText: "คืนเงินสูงที่สุด \n ในอุตสากรรรม", //业界最高返水
                iconRightText: "เครดิตพิเศษ \n ได้รับการรับรอง", //独家授权信用
            },
            {
                providerCode: "CML",
                providerName: "CMD 体育",
                description:
                    "CMD กีฬา \n ฟังก์ชั่นคืนเงินอัตโนมัติ เดิมพันการแข่งขันสด \n ชมสถิติกีฬาเพื่อการวิเคราะห์", //独家赛马/赛狗/拳击等多种赛事，梦幻对决实时开赛，秒下注秒开奖！还有轮盘/彩票等数字游戏随心选择！
                iconLeftText: "คุณภาพดีและ \n เสถียรมากที่สุด", //最稳固的品质
                iconBonus: "1",
                iconBonusText: "คืนเงินสูงที่สุด \n ในอุตสากรรรม", //业界最高返水
                iconRightText: "เครดิตพิเศษ \n ได้รับการรับรอง", //独家授权信用
            },
            {
                providerCode: "SBO",
                providerName: "SBO 体育",
                description: "SBO กีฬา \n กีฬาน้ำดี ตัวเลือกกว่า 500 รายการ", //独家赛马/赛狗/拳击等多种赛事，梦幻对决实时开赛，秒下注秒开奖！还有轮盘/彩票等数字游戏随心选择！
                iconLeftText: "คุณภาพดีและ \n เสถียรมากที่สุด", //最稳固的品质
                iconBonus: "1",
                iconBonusText: "คืนเงินสูงที่สุด \n ในอุตสากรรรม", //业界最高返水
                iconRightText: "เครดิตพิเศษ \n ได้รับการรับรอง", //独家授权信用
            },
        ],
    },
    {
        gameName: "电子竞技",
        providerName: "ESports",
        color: ["#00C853", "#00C853", "#66E67F"],
        egameNames: "E-SPORT",
        egameName: "TLC ESPORT BETTING",
        topbanner: "/cn/img/game/esport/top.png",
        centerbanner: "/cn/img/game/esport/center.png",
        centertxt:
            "提供足球、篮球、网球、电竞等数十种体育项目投注盘口，业界领先赔率，每日千场滚球赛事一网打尽。提供LOL、dota2、王者荣耀、绝地求生、csgo等数十种电竞赛事投注盘口，独创多种串关玩法，电竞赛事应有尽有。",
        iconleft: "/th/img/game/icon-01.png",
        iconbonus: "/th/img/game/icon-03.png",
        iconright: "/th/img/game/icon-02.png",

        howtoplaytitle: [
            "什么是体育投注？",
            "什么叫做滚球？",
            "让分后，XXXXXX？",
            "行为限制的介绍？",
        ],
        howtoplay: [
            "什么叫做滚球 内容",
            "让分后，XXXXXX？内容",
            "行为限制的介绍？内容",
        ],
        bonus: "1",
        gameList: [
            {
                providerCode: "IPES",
                providerName: "乐天堂电竞",
                description:
                    "FUN88 อีสปอร์ต \n FUN88 อีสปอร์ต ที่สุดของอีสปอร์ต \n พร้อมรูปแบบความสนุกอีกมากมาย", //顶级的技术与特性，为玩家不一样的刺激体验。
                iconLeftText: "คุณภาพดีและ \n เสถียรมากที่สุด", //最稳固的品质
                iconBonus: "1",
                iconBonusText: "คืนเงินสูงที่สุด \n ในอุตสากรรรม", //业界最高返水
                iconRightText: "เครดิตพิเศษ \n ได้รับการรับรอง", //独家授权信用
            },
            {
                providerCode: "TFG",
                providerName: "TF 电竞",
                description:
                    "TF อีสปอร์ต \n มากกว่าความสนุก! ที่สุดของความหลากหลาย \n และอัตราจ่ายสูง สตรีมสด คมชัด เพื่อการรับชมที่สมจริง", //提供PUBG、LoL、CSGO、Dota2等各大国内外电竞赛事。
                iconLeftText: "คุณภาพดีและ \n เสถียรมากที่สุด", //最稳固的品质
                iconBonus: "1",
                iconBonusText: "คืนเงินสูงที่สุด \n ในอุตสากรรรม", //业界最高返水
                iconRightText: "เครดิตพิเศษ \n ได้รับการรับรอง", //独家授权信用
            },
        ],
    },
    {
        gameName: "真人娱乐",
        providerName: "LiveCasino",
        color: ["#E91E63", "#FF679B", "#E91E63"],
        egameNames: "LIVE CASINO",
        egameName: "ASIA GAMING LIVE CASINO",
        topbanner: "/cn/img/game/casino/products-casino.png",
        centerbanner: "/cn/img/game/casino/live-evo-bg.png",
        centertxt:
            "提供足球、篮球、网球、电竞等数十种体育项目投注盘口，业界领先赔率，每日千场滚球赛事一网打尽。提供LOL、dota2、王者荣耀、绝地求生、csgo等数十种电竞赛事投注盘口，独创多种串关玩法，电竞赛事应有尽有。",
        iconleft: "/cn/img/game/icon-01.png",
        iconbonus: "/th/img/game/icon-03.png",
        iconright: "/cn/img/game/icon-02.png",

        howtoplaytitle: [
            "什么是体育投注？",
            "什么叫做滚球？",
            "让分后，XXXXXX？",
            "行为限制的介绍？",
        ],
        /* 玩法内容 */
        howtoplay: [
            "提供足球、篮球、网球、电竞等数十种体育项目投注盘口，业界领先赔率，每日千场滚球赛事一网打尽。提供LOL、dota2、王者荣耀、绝地求生、csgo等数十种电竞赛事投注盘口，独创多种串关玩法，电竞赛事应有尽有。提供足球、篮球、网球、电竞等数十种体育项目投注盘口，业界领先赔率，每日千场滚球赛事一网打尽。提供LOL、dota2、王者荣耀、绝地求生、csgo等数十种电竞赛事投注盘口，独创多种串关玩法，电竞赛事应有尽有。提供足球、篮球、网球、电竞等数十种体育项目投注盘口，业界领先赔率，每日千场滚球赛事一网打尽。提供LOL、dota2、王者荣耀、绝地求生、csgo等数十种电竞赛事投注盘口，独创多种串关玩法，电竞赛事应有尽有。",
            "让分后，XXXXXX？内容",
            "行为限制的介绍？内容",
        ],
        bonus: "12" /* 返水 */,
    },
    {
        gameName: "棋牌游戏",
        providerName: "P2P",
        color: ["#99683D", "#99683D", "#C9952E"],
        egameNames: "P2P GAMES",
        egameName: "KAIYUAN P2P GAMES",
        topbanner: "/cn/img/game/chess/top.png",
        centerbanner: "/cn/img/game/chess/center.png",
        centertxt:
            "提供足球、篮球、网球、电竞等数十种体育项目投注盘口，业界领先赔率，每日千场滚球赛事一网打尽。提供LOL、dota2、王者荣耀、绝地求生、csgo等数十种电竞赛事投注盘口，独创多种串关玩法，电竞赛事应有尽有。",
        iconleft: "/cn/img/game/icon-01.png",
        iconbonus: "/th/img/game/icon-03.png",
        iconright: "/cn/img/game/icon-02.png",

        howtoplaytitle: [
            "什么是体育投注？",
            "什么叫做滚球？",
            "让分后，XXXXXX？",
            "行为限制的介绍？",
        ],
        howtoplay: [
            "什么叫做滚球 内容",
            "让分后，XXXXXX？内容",
            "行为限制的介绍？内容",
        ],
        bonus: "12",
    },
    {
        gameName: "彩票游戏",
        providerName: "KenoLottery",
        color: ["#A633CC", "#A633CC", "#D56AEB"],
        egameNames: "LOTTERY",
        egameName: "VR LOTTERY",
        topbanner: "/cn/img/game/lottery/top.png",
        centerbanner: "/cn/img/game/lottery/center_TC.png",
        centertxt:
            "提供足球、篮球、网球、电竞等数十种体育项目投注盘口，业界领先赔率，每日千场滚球赛事一网打尽。提供LOL、dota2、王者荣耀、绝地求生、csgo等数十种电竞赛事投注盘口，独创多种串关玩法，电竞赛事应有尽有。",
        iconleft: "/th/img/game/icon-01.png",
        iconbonus: "/th/img/game/icon-03.png",
        iconright: "/th/img/game/icon-02.png",

        howtoplaytitle: [
            "什么是体育投注？",
            "什么叫做滚球？",
            "让分后，XXXXXX？",
            "行为限制的介绍？",
        ],
        howtoplay: [
            "什么叫做滚球 内容",
            "让分后，XXXXXX？内容",
            "行为限制的介绍？内容",
        ],
        bonus: "12",
        gameList: [
            {
                providerCode: "YBK",
                providerName: "醉心彩票",
                description: "沉浸式的投注体验，还有聊天室导师带单功能！",
                iconLeftText: "คุณภาพดีและ \n เสถียรมากที่สุด", //最稳固的品质
                iconBonus: "12",
                iconBonusText: "คืนเงินสูงที่สุด \n ในอุตสากรรรม", //业界最高返水
                iconRightText: "เครดิตพิเศษ \n ได้รับการรับรอง", //独家授权信用
            },
            {
                providerCode: "SGW",
                providerName: "双赢彩票",
                description:
                    "简单操作还拥有最顺畅的游戏界面，内含幸运飞艇/极速牛牛/极速赛车等多种游戏！立即体验业界最极致的快乐彩！",
                iconLeftText: "คุณภาพดีและ \n เสถียรมากที่สุด", //最稳固的品质
                iconBonus: "12",
                iconBonusText: "คืนเงินสูงที่สุด \n ในอุตสากรรรม", //业界最高返水
                iconRightText: "เครดิตพิเศษ \n ได้รับการรับรอง", //独家授权信用
            },
            {
                providerCode: "LBK",
                providerName: "LB 快乐彩",
                description:
                    "超多彩种选择让玩家乐在其中，娱乐区/普通区/高级区满足您的各种需求！",
                iconLeftText: "คุณภาพดีและ \n เสถียรมากที่สุด", //最稳固的品质
                iconBonus: "12",
                iconBonusText: "คืนเงินสูงที่สุด \n ในอุตสากรรรม", //业界最高返水
                iconRightText: "เครดิตพิเศษ \n ได้รับการรับรอง", //独家授权信用
            },
        ],
    },
    {
        gameName: "电子游戏",
        providerName: "Slot",
        color: ["#673AB7", "#FF679B", "#967ACC"],
        egameNames: "SLOT GAMES",
        egameName: "FISHING KING 2",
        topbanner: "/cn/img/game/slot/top.png",
        centerbanner: "/cn/img/game/slot/center.png",
        centertxt:
            "提供足球、篮球、网球、电竞等数十种体育项目投注盘口，业界领先赔率，每日千场滚球赛事一网打尽。提供LOL、dota2、王者荣耀、绝地求生、csgo等数十种电竞赛事投注盘口，独创多种串关玩法，电竞赛事应有尽有。",
        iconleft: "/cn/img/game/icon-01.png",
        iconbonus: "/th/img/game/icon-03.png",
        iconright: "/cn/img/game/icon-02.png",

        howtoplaytitle: [
            "什么是体育投注？",
            "什么叫做滚球？",
            "让分后，XXXXXX？",
            "行为限制的介绍？",
        ],
        howtoplay: [
            "什么叫做滚球 内容",
            "让分后，XXXXXX？内容",
            "行为限制的介绍？内容",
        ],
        bonus: "12",
    },
];

export default pubilcData;

export const mapGameProduct = [
    { name: "กีฬา", productCode: "Sportsbook", piwikName: "Sports" },
    { name: "อีสปอร์ต", productCode: "ESports", piwikName: "ESports" },
    {
        name: "เกมความเร็ว",
        productCode: "InstantGames",
        piwikName: "InstantGames",
    },
    { name: "คาสิโน", productCode: "LiveCasino", piwikName: "LiveDealer" },
    { name: "สล็อต", productCode: "Slot", piwikName: "SlotFishing" },
    { name: "เกม-3-มิติ", productCode: "P2P", piwikName: "P2P" },
    { name: "หวย", productCode: "KenoLottery", piwikName: "Lottery" },
];

export const mapGameProvider = [
    {
        code: "Sportsbook",
        providers: [
            { providerCode: "OWS", name: "saba" },
            { providerCode: "CML", name: "cmd" },
            { providerCode: "IPSB", name: "im" },
            { providerCode: "SBT", name: "bti" },
            // { providerCode: "VTG", name: "v2-กีฬาจำลอง" },
            { providerCode: "SBO", name: "sbo" },
        ],
    },
    {
        code: "ESports",
        providers: [
            { providerCode: "TFG", name: "tfg" },
            { providerCode: "IPES", name: "fun88" },
        ],
    },
    {
        code: "InstantGames",
        providers: [
            { providerCode: "SPR", name: "spribe" },
            { providerCode: "GLX", name: "galaxsys" },
            { providerCode: "gamelist", name: "gamelist" },
        ],
    },
    {
        code: "LiveCasino",
        providers: [
            { providerCode: "WEC", name: "wec" },
            { providerCode: "GPI", name: "fun88" },
            { providerCode: "EVO", name: "evolution" },
            { providerCode: "SXY", name: "sxy" },
            { providerCode: "SAL", name: "sa-gaming" },
            { providerCode: "AGL", name: "asia-gaming" },
            { providerCode: "WMC", name: "wm-casino" },
            { providerCode: "TG", name: "pragmatic-play" },
            { providerCode: "DGG", name: "dream-gaming" },
            { providerCode: "gamelist", name: "gamelist" },
        ],
    },
    {
        code: "Slot",
        providers: [
            { providerCode: "TG", name: "pragmatic-play" },
            { providerCode: "SPX", name: "spinix" },
            { providerCode: "AMB", name: "amb" },
            { providerCode: "PGS", name: "pg-soft" },
            { providerCode: "JKR", name: "jk" },
            { providerCode: "MGP", name: "microgaming" },
            { providerCode: "JIR", name: "jili" },
            { providerCode: "SPG", name: "spadegaming" },
            { providerCode: "BSG", name: "betsoft" },
            { providerCode: "JIF", name: "ยิงปลา-jili" },
            { providerCode: "SWF", name: "skywind" },
            { providerCode: "CQG", name: "cq9" },
            { providerCode: "PNG", name: "png" },
            { providerCode: "IMOPT", name: "playtech" },
            { providerCode: "IMONET", name: "netent" },
            { providerCode: "EVP", name: "evoplay" },
            { providerCode: "HBN", name: "habanero" },
            { providerCode: "BNG", name: "booongo" },
            { providerCode: "NGS", name: "nagagames" },
            { providerCode: "HSW", name: "hacksaw" },
            { providerCode: "EVOBT", name: "big-time" },
            { providerCode: "EVORT", name: "red-tiger" },
            { providerCode: "FCG", name: "facai" },
            { providerCode: "gamelist", name: "gamelist" },
        ],
    },
    {
        code: "P2P",
        providers: [
            { providerCode: "TGP", name: "tgp" },
            { providerCode: "KPK", name: "kpk" },
            { providerCode: "JKR", name: "jkr" },
            { providerCode: "gamelist", name: "gamelist" },
        ],
    },
    {
        code: "KenoLottery",
        providers: [
            { providerCode: "TCG", name: "tcg" },
            { providerCode: "GPK", name: "gpk" },
            { providerCode: "SLC", name: "slc" },
            { providerCode: "gamelist", name: "gamelist" },
        ],
    },
];
// 查找對應的 providerName，providerCode 有重複，因此要判斷 gameCateCode (ex: Sportsbook、ESports...)
export const convertCodeToName = (gameCateCode, providerCode) => {
    const providerName = mapGameProvider
        .find((cate) => cate.code === gameCateCode)
        ?.providers.find((provider) => provider.providerCode === providerCode)?.name;
    return providerName || "";
};
// 查找對應的 providerCode
export const convertNameToCode = (gameCateCode, providerName) => {
    const providerCode = mapGameProvider
        .find((cate) => cate.code === gameCateCode)
        ?.providers.find((provider) => provider.name === providerName)?.providerCode;
    return providerCode || "";
};

export const mapAnnouncementType = [
    { name: "Sportsbook", optionType: "Sportsbook" },
    { name: "LiveCasino", optionType: "Casino" },
    { name: "Slot", optionType: "Slot" },
    { name: "P2P", optionType: "Poker" },
    { name: "KenoLottery", optionType: "Keno" },
];

// 2023/01/02 因P5 Sequence api由CGMS維護，與BOBF WalletProviderMapping
//內的provider code對應不起來，因此在P5不使用api WalletProviderMapping
//改為由前端hardcode 配對provider to wallet。

export const WalletProviderMapping = {
    SLOT: [
        { provider: "SPX", gameType: "Slot" },
        { provider: "HBN", gameType: "Slot" },
        { provider: "EVP", gameType: "Slot" },
        { provider: "NGS", gameType: "Slot" },
        { provider: "AMB", gameType: "Slot" },
        { provider: "JIR", gameType: "Slot" },
        { provider: "MGP", gameType: "Slot" },
        { provider: "BSG", gameType: "Slot" },
        { provider: "CQG", gameType: "Slot" },
        { provider: "SPG", gameType: "Slot" },
        { provider: "LX", gameType: "Slot" },
        { provider: "SWF", gameType: "Slot" },
        { provider: "JKR", gameType: "Slot" },
        { provider: "TG", gameType: "Slot" },
        { provider: "TGP", gameType: "Slot" },
        { provider: "IMOJDB", gameType: "Slot" },
        { provider: "IMONET", gameType: "Slot" },
        { provider: "PGS", gameType: "Slot" },
        { provider: "PNG", gameType: "Slot" },
        { provider: "BNG", gameType: "Slot" },
        { provider: "HSW", gameType: "Slot" },
        { provider: "EVOBT", gameType: "Slot" },
        { provider: "EVORT", gameType: "Slot" },
        { provider: "FCG", gameType: "Slot" },
    ],
    LD: [
        { provider: "WEC", gameType: "LiveCasino" },
        { provider: "BGG", gameType: "LiveCasino" },
        { provider: "ABT", gameType: "LiveCasino" },
        { provider: "NLE", gameType: "LiveCasino" },
        { provider: "SAL", gameType: "LiveCasino" },
        { provider: "TG", gameType: "LiveCasino" },
        { provider: "SXY", gameType: "LiveCasino" },
        { provider: "YBL", gameType: "LiveCasino" },
        { provider: "TGP", gameType: "LiveCasino" },
        { provider: "WMC", gameType: "LiveCasino" },
        { provider: "GPI", gameType: "LiveCasino" },
        { provider: "EVO", gameType: "LiveCasino" },
        { provider: "AGL", gameType: "LiveCasino" },
        { provider: "DGG", gameType: "LiveCasino" },
    ],
    LBK: [{ provider: "LBK", gameType: "KenoLottery" }],
    IMOPT: [{ provider: "IMOPT", gameType: "Slot" }],
    KENO: [
        { provider: "SGW", gameType: "KenoLottery" },
        { provider: "GPK", gameType: "KenoLottery" },
        { provider: "TCG", gameType: "KenoLottery" },
    ],
    SLC: [{ provider: "SLC", gameType: "KenoLottery" }],
    SB: [
        { provider: "SBT", gameType: "Sportsbook" },
        { provider: "CML", gameType: "Sportsbook" },
        { provider: "IPSB", gameType: "Sportsbook" },
        { provider: "OWS", gameType: "Sportsbook" },
        { provider: "VTG", gameType: "Sportsbook" },
        { provider: "YBS", gameType: "Sportsbook" },
        { provider: "SB", gameType: "Sportsbook" },
        { provider: "CMD", gameType: "Sportsbook" },
        { provider: "SBO", gameType: "Sportsbook" },
        { provider: "TFG", gameType: "ESports" },
        { provider: "IPES", gameType: "ESports" },
        { provider: "TFG", gameType: "ESports" },
    ],
    P2P: [
        { provider: "KPK", gameType: "P2P" },
        { provider: "IPK", gameType: "P2P" },
        { provider: "JKR", gameType: "P2P" },
        { provider: "TGP", gameType: "P2P" },
        { provider: "JBP", gameType: "P2P" },
        { provider: "SPR", gameType: "InstantGames" },
        { provider: "GLX", gameType: "InstantGames" },
    ],
    KYG: [{ provider: "KYG", gameType: "P2P" }],
    YBP: [{ provider: "YBP", gameType: "P2P" }],
    YBF: [{ provider: "YBF", gameType: "Slot" }],
    FISH: [{ provider: "JIF", gameType: "Slot" }],
    YBK: [{ provider: "YBK", gameType: "KenoLottery" }],
};

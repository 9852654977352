import React from "react";
import { get, post } from "$ACTIONS/TlcRequest";
import { Button, Input, Spin, Row, Col, Modal, Form } from "antd";
import { ApiPort } from "$ACTIONS/TLCAPI";

import Router from "next/router";
import CryptoJS from "crypto-js";
import { getQueryVariable, Cookie, getE2BBValue } from "$ACTIONS/helper";
import { Cookie as CookieUtil } from "$ACTIONS/util";

import Captcha from "../Captcha";
import { SuccessIcon } from "./RegisterIcnoSvg";
import { toast } from "react-toastify";

import { userCenterActions } from "../../store/userCenterSlice";
import { connect } from "react-redux";
import { getMemberInfoAction } from "$STORE/thunk/memberThunk";
import ForgotPwd from "@/ForgotPwd/";
import OpenGame from "@/Games/openGame";
import { gameActions } from "$STORE/gameSlice";
import showMessage from "$ACTIONS/messageUtil";

class _Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            UserName: "",
            Password: "",
            userNameInputLengthIsValid: false,
            passwordInputLengthIsValid: false,
            loginAt: "homepage",
            errorMessage: "", // for login modal
            isLogin: false,
            Spin: false,
            loadinglogin: false,
            isRegister: false,
            challengeUuid: "",
            captchaVisible: false,
        };

        this.Showmodal = React.createRef();
        this.Login = this.Login.bind(this);
        this.successLogin = this.successLogin.bind(this);
        this.isFirstLogin = null;
        this.isCaptchaOn = true;
    }

    componentDidMount() {
        let _href = global.location.href;
        if (
            this.props.home &&
            _href.includes("openForgotPassword=1") &&
            !_href.includes("#alreadyAutoOpen")
        ) {
            setTimeout(() => {
                global.location.hash = "#alreadyAutoOpen";
            }, 1000);
            this.openForgotPasswordModal();
        }
        this.props.RefLogin && this.props.RefLogin(this);
        // QRT 参数自动登陆
        function decrypt(text, key) {
            let md5Key = CryptoJS.MD5(key).toString();
            let keyHex = CryptoJS.enc.Hex.parse(md5Key);
            let decrypt = CryptoJS.TripleDES.decrypt(text, keyHex, {
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7,
            });
            //解析数据后转为UTF-8
            let parseData = decrypt.toString(CryptoJS.enc.Utf8);
            return parseData;
        }

        // QRT加密参数登录
        const QRT = getQueryVariable("QRT");
        if (QRT) {
            const str = decrypt(decodeURIComponent(QRT), "TLCQuickReg");
            const tokenArray = str.split("&");
            let tokenObject = {};
            tokenArray.forEach((v) => {
                let tokenKeyVal = v.split("=");
                tokenObject[tokenKeyVal[0]] = tokenKeyVal[1];
            });
            this.RefreshTokenApi(
                tokenObject.refreshToken,
                "Bearer " + tokenObject.token,
                tokenObject.isRegistration
            );
        }

        // redirectToken参数登录
        // const redirectToken = getQueryVariable('redirectToken');
        // if (redirectToken) {
        // 	console.log(redirectToken, global.redirectTokenVariable);
        // 	if (redirectToken === global.redirectTokenVariable) return;
        // 	global.redirectTokenVariable = redirectToken;
        // 	post(ApiPort.VerifyRedirectToken, {
        // 		Source: '8Shop',
        // 		Destination: 'TLCP4',
        // 		RedirectToken: redirectToken,
        // 		ClientId: 'TLC.Native.App',
        // 		ClientSecret: 'muitten',
        // 		Scope: 'Mobile.Service offline_access',
        // 		AppId: 'net.funpodium.tlc',
        // 		SiteId: '16'
        // 	})
        // 		.then((res) => {
        // 			if (res.isSuccess) {
        // 				// 传function代表临时退出，8shop会更新会员信息。
        // 				localStorage.getItem('access_token') && global.globalExit(true);
        // 				/**res.result.isNewlyCreated
        // 			 * 为true：会员从八号商店登录到官网，会包含memberInfo 参数
        // 			 * 为false：会员在官网登入，跳转至8号店，然后又从8号店跳转回去官网，不会包含memberInfo 参数
        // 			 */
        // 				if (res.result.isNewlyCreated) {
        // 					this.successLogin(res.result.accessToken, res.result.memberInfo, true);
        // 				} else {

        // 				}
        // 			} else {
        // 				Router.push('/');
        // 			}
        // 		})
        // 		.catch((err) => {
        // 			console.log(err);
        // 		});
        // }

        if (localStorage.getItem("autoLogin")) {
            this.setState(
                {
                    UserName: localStorage.getItem("userName"),
                    Password: localStorage.getItem("userNum"),
                },
                () =>
                    setTimeout(() => {
                        this.Login();
                    }, 3000)
            );
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (
            prevState.userNameInputLengthIsValid !==
                this.state.userNameInputLengthIsValid ||
            prevState.passwordInputLengthIsValid !==
                this.state.passwordInputLengthIsValid
        ) {
            if (
                this.state.userNameInputLengthIsValid &&
                this.state.passwordInputLengthIsValid
            ) {
                this.setState({
                    errorMessage: "",
                });
            }
        }
    };

    RefreshTokenApi(refreshToken, memberToken, isRegistration) {
        const data = {
            grantType: "refresh_token",
            clientId: "TLC.Native.App",
            clientSecret: "muitten",
            refreshToken: refreshToken,
        };
        this.setState({ loadinglogin: true });
        const isOldAccount = !!localStorage.getItem("access_token");
        if (isOldAccount) {
            CookieUtil(null, null);
            localStorage.clear();
            sessionStorage.clear();
        }
        post(ApiPort.RefreshTokenapi, data, memberToken)
            .then((res) => {
                if (res) {
                    if (res.access_token && res.refresh_token) {
                        localStorage.setItem(
                            "access_token",
                            JSON.stringify("bearer " + res.access_token)
                        );
                        this.props.getMemberInfo((memeberInfoResponse) => {
                            this.setState({ loadinglogin: false });
                            const memeberInfoResponseResultMemberInfo =
                                memeberInfoResponse.result.memberInfo;
                            let tokenMemberInfo = {
                                loginOTP: memeberInfoResponse.result.isLoginOTP,
                            };
                            isRegistration === "1" &&
                                (this.isFirstLogin = true);
                            for (const property in memeberInfoResponseResultMemberInfo) {
                                tokenMemberInfo[
                                    property.substr(0, 1).toLocaleLowerCase() +
                                        property.substr(1, property.length - 1)
                                ] =
                                    memeberInfoResponseResultMemberInfo[
                                        property
                                    ];
                            }
                            this.successLogin(
                                res,
                                tokenMemberInfo,
                                isOldAccount ? "refresh" : undefined
                            );
                        }, true);
                    } else {
                        this.setState({ loadinglogin: false });
                        showMessage(
                            "error",
                            "หมดเวลา กรุณาเข้าสู่ระบบอีกครั้ง",
                            100
                        ); //请重新登录,访问过期
                        setTimeout(() => {
                            global.globalExit();
                            Router.push("/");
                        }, 2000);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                this.setState({ loadinglogin: false });
            });
    }
    PushLoginPiwik = (result) => {
        const category = this.props.home ? "Home" : "Login";
        const action = this.props.home ? "Submit Login" : "Password Login";
        Pushgtagdata(
            category,
            action,
            `${category}_S_Login`,
            result ? 2 : 1,
            [
                {
                    customVariableKey: result ? false : `${category}_S_Login_ErroMsg`,
                    customVariableValue: result
                        ? false
                        : "ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้องโปรดลองอีกครั้ง!",
                },
            ]
        );
    };
    Login() {
        const { UserName, Password, loginAt } = this.state;
        if (UserName.trim().length === 0) {
            if (loginAt === "homepage") {
                showMessage("error", "กรุณากรอกยูสเซอร์เนมและรหัสผ่าน", 100); //请输入您的用户名。
                return;
            }
            this.setState({
                errorMessage: "กรุณากรอกยูสเซอร์เนม", //请输入您的用户名。
            });
            return;
        }
        if (Password.trim().length === 0) {
            if (loginAt === "homepage") {
                showMessage("error", "กรุณากรอกรหัสผ่าน", 100); //请输入您的密码。
                return;
            }
            this.setState({
                errorMessage: "กรุณากรอกรหัสผ่าน", //请输入您的密码。
            });
            return;
        }
        let Times =
            this.Captcha && this.Captcha.state && this.Captcha.state.attempts
                ? this.Captcha.state.attempts
                : 3;
        if (Cookie.Get("FaileCoun" + UserName) >= Times) {
            if (this.Captcha) {
                this.Captcha.getCaptchaInfo(UserName);
            }
            if (this.state.challengeUuid == "") {
                this.setState({ captchaVisible: true });
                return;
            }
        }
        this.continueToLogin();
    }
    continueToLogin = () => {
        this.setState({
            loadinglogin: true,
            errorMessage: "",
        });
        const { UserName, Password, loginAt } = this.state;
        const Postdata = {
            hostName: global.location.origin,
            grantType: "password",
            clientId: "Fun88.TH.App",
            clientSecret: "FUNmuittenTH",
            username: UserName.trim(),
            password: Password,
            scope: "Mobile.Service offline_access",
            appId: "net.funpodium.fun88",
            siteId: 37,
            DeviceSignatureBlackBox: getE2BBValue(),
            captcha:
                this.state.challengeUuid != ""
                    ? {
                          challenge: this.state.challengeUuid,
                          seccode: "",
                          type: "",
                          userId: "",
                          validate: "",
                      }
                    : "",
        };
        post(ApiPort.PostLogin, Postdata)
            .then((res) => {
                console.log("login res", res);
                if (
                    res?.isSuccess &&
                    res?.result?.memberInfo?.currency === "THB"
                ) {
                    !this.state.isRegister && this.PushLoginPiwik(true);
                    this.successLogin(res.result, UserName);
                } else {
                    !this.state.isRegister && this.PushLoginPiwik(false);
                    if (
                        res?.result?.memberInfo?.currency &&
                        res?.result?.memberInfo?.currency !== "THB"
                    ) {
                        if (loginAt === "homepage") {
                            showMessage(
                                "error",
                                "ยูสเซอร์เนมหรือรหัสผ่านไม่ถูกต้อง",
                                100
                            ); //用户名或密码错误
                        } else if (loginAt === "modal") {
                            this.setState({
                                errorMessage:
                                    "ยูสเซอร์เนมหรือรหัสผ่านไม่ถูกต้อง", //用户名或密码错误
                            });
                        }
                    } else if (res?.errors?.[0]?.errorCode == "MEM00060") {
                        this.overLimits();
                    } else if (res?.errors?.[0]?.errorCode == "MEM00061") {
                        showMessage(
                            "error",
                            "บัญชีของคุณไม่สามารถใช้งานได้ โปรดติดต่อฝ่ายบริการลูกค้า",
                            100
                        ); //您的账号无法使用，请联系在线客服！
                    } else if (res?.errors?.[0]?.errorCode == "MEM00141") {
                        showMessage(
                            "error",
                            "บัญชีของคุณถูกปิดใช้งาน โปรดติดต่อฝ่ายบริการลูกค้า",
                            100
                        ); //您的帳戶已停用。 請聯絡客服。
                    } else {
                        showMessage(
                            "error",
                            res?.errors?.[0]?.description ||
                                "การเข้าสู่ระบบล้มเหลว กรุณาลองอีกครั้งหรือติดต่อฝ่ายบริการลูกค้า", //登录失败！请稍后重试，或联系客服
                            100
                        );
                    }

                    this.setState({
                        loadinglogin: false,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                !this.state.isRegister && this.PushLoginPiwik(false);
                const FaileCounName = "FaileCoun" + UserName;
                const count = Cookie.Get(FaileCounName);
                if (count && count >= 4) {
                    this.overLimits();
                } else if (
                    error?.errors?.[0]?.errorCode == "MEM00059" ||
                    error?.errors?.[0]?.errorCode == "MEM00004"
                ) {
                    if (loginAt === "homepage") {
                        showMessage(
                            "error",
                            "ยูสเซอร์เนมหรือรหัสผ่านไม่ถูกต้อง",
                            100
                        ); //用户名或密码错误
                    } else if (loginAt === "modal") {
                        this.setState({
                            errorMessage: "ยูสเซอร์เนมหรือรหัสผ่านไม่ถูกต้อง", //用户名或密码错误
                        });
                    }
                } else {
                    showMessage(
                        "error",
                        res?.errors?.[0]?.description ||
                            "การเข้าสู่ระบบล้มเหลว กรุณาลองอีกครั้งหรือติดต่อฝ่ายบริการลูกค้า", //登录失败！请稍后重试，或联系客服
                        100
                    );
                }
                this.setState({
                    loadinglogin: false,
                    challengeUuid: "",
                });

                // let errorList =["MEM00004","MEM00059","MEM00060","MEM00140","CAPTCHA_ERROR"]
                if (this.isCaptchaOn) {
                    this.logInputFaile();
                }
            });
    };
    successLogin(token, userName, isRedirect) {
        console.log("---------->", token);
        if (token && token.accessToken && token.accessToken.accessToken) {
            this.props.setMemberFirstLogin(this.state.isRegister);
            this.setState({
                isLogin: true,
                //isResetPwd: member.revalidate,
                UserName: userName,
                isLoginVisible: false,
            });
            Cookie.Delete("FaileCoun" + userName);
            localStorage.setItem("UserName", userName);
            //localStorage.setItem('PreferWallet', member.preferWallet);
            localStorage.setItem(
                "access_token",
                JSON.stringify("bearer " + token.accessToken.accessToken)
            );
            localStorage.setItem(
                "refresh_token",
                JSON.stringify(token.accessToken.refreshToken)
            );
            //获取会员资料
            this.props.getMemberInfo((res) => {
                // 第一次註冊後的登入，只顯示註冊成功彈窗
                if (this.state.isRegister) {
                    console.log("hi~");
                } else {
                    showMessage("success", `ยินดีต้อนรับ，${userName}`, 100); // 欢迎您
                }
                if (isRedirect) {
                    Router.push("/").then(() => {
                        this.props.AlreadLogin(); // UI登录成功
                    });
                } else if (this.props.cacheGame) {
                    this.QuickStartGame &&
                        this.QuickStartGame.openGame(this.props.cacheGame);
                    this.props.setCacheGame(null);
                    this.props.AlreadLogin(); // UI登录成功
                } else {
                    this.props.AlreadLogin(); // UI登录成功
                }
                this.setState({
                    loadinglogin: false,
                });
                //客服链接更正
                get(ApiPort.GETLiveChat).then((res) => {
                    if (res && res.url) {
                        localStorage.setItem("serverUrl", res.url);
                    }
                });
            }, true);
            //localStorage.setItem('memberInfo', JSON.stringify(memberRes));
            sessionStorage.setItem("isLogin", true);

            if (!this.props.home) {
                this.props.handleEvent(); // 关闭弹窗
            }

            if (this.state.isRegister || this.isFirstLogin) {
                Router.push("/");
                showMessage("success", `ยินดีต้อนรับ，${userName}`, 100); // 欢迎您
                return;
            }
            if (isRedirect === "refresh") {
                window.location.href = "/th/";
                return;
            }
        } else {
            this.setState({
                loadinglogin: false,
            });
        }

        localStorage.removeItem("userName");
        localStorage.removeItem("userNum");
        localStorage.removeItem("autoLogin");
        localStorage.removeItem("RestrictAccessCode");
    }

    UserInput = (e, t) => {
        let tempValue = e.target.value;
        if (t == "USER") {
            this.setState(
                {
                    UserName: tempValue,
                    userNameInputLengthIsValid: tempValue.length != 0,
                },
                () => {
                    this.OnBlur();
                }
            );
            localStorage.setItem("userName", tempValue);
        } else if (t == "PWD") {
            this.setState(
                {
                    Password: tempValue,
                    passwordInputLengthIsValid: tempValue.length != 0,
                },
                () => {
                    this.OnBlur();
                }
            );
            localStorage.setItem("userNum", tempValue);
        }
    };

    OnBlur = () => {
        const { UserName, Password } = this.state;
        if (UserName.trim().length === 0 && Password.trim().length === 0) {
            this.setState({ errorMessage: "กรุณากรอกยูสเซอร์เนมและรหัสผ่าน" }); //请输入您的用户名或密码。
        } else if (UserName.trim().length === 0) {
            this.setState({ errorMessage: "กรุณากรอกยูสเซอร์เนม​" }); //請輸入您的用戶名
        } else if (Password.trim().length === 0) {
            this.setState({ errorMessage: "กรุณากรอกรหัสผ่าน" }); //请输入您的密码。
        }
    };

    Registerdata(name, pwd) {
        this.setState(
            {
                UserName: name,
                Password: pwd,
                isRegister: true,
            },
            () => {
                this.Login();
            }
        );
    }

    HandleEnterKey = (e) => {
        if (this.state.loadinglogin != true) {
            if (
                localStorage.getItem("access_token") == null &&
                this.state.isLogin == false
            ) {
                if (this.props.home) {
                    document.getElementById("SubmitGeet").click();
                } else {
                    document.getElementById("SubmitGeetPopUp").click();
                }
            }
        }
    };

    /***是否开启图形验证*/
    getCaptchaSwitch = () => {
        get(ApiPort.Captchaswitch).then((res) => {
            if (res) {
                if (res.isCaptchaOn) {
                    this.isCaptchaOn = true;
                }
                this.continueToLogin();
            }
        });
    };
    logInputFaile = () => {
        const { UserName } = this.state;
        const FaileCounName = "FaileCoun" + UserName;
        /* 输入错误次数 */
        var count = Cookie.Get(FaileCounName);
        if (count == null || count == "") {
            //第一次输入错误
            Cookie.Create(FaileCounName, 1);
        } else {
            var count = Cookie.Get(FaileCounName);
            let num = ++count;
            if (count <= 2) {
                Cookie.Create(FaileCounName, num);
            } else {
                Cookie.Create(FaileCounName, num);
            }
        }
    };
    onMatch = (id) => {
        this.setState(
            {
                challengeUuid: id,
                captchaVisible: false,
            },
            () => {
                this.Login();
            }
        );
    };
    overLimits = () => {
        Modal.confirm({
            className: "confirm-general-modal login-exceed-modal",
            width: 400,
            icon: <></>,
            title: "เข้าสู่ระบบเกินที่กำหนด", //登入超出限制
            centered: true,
            okText: "เจ้าหน้าที่ฝ่ายบริการ", //聯絡服務人員
            cancelText: "ปิด",
            zIndex: 2000,
            content: (
                <>
                    <img src="/th/img/icons/icon-warn.svg" />
                    <p>
                        เข้าสู่ระบบล้มเหลวเกิน 5 ครั้ง
                        กรุณาติดต่อเจ้าหน้าที่ฝ่ายบริการ
                    </p>
                </>
                // 登入失敗超過5次，請聯絡我們的服務人員。
            ),
            onOk: () => {
                global.PopUpLiveChat();
            },
        });
    };
    openForgotPasswordModal = () => {
        this.Showmodal.current.showModal("1");
    };
    render() {
        const { UserName, Password, captchaVisible, sucessModal } = this.state;
        return (
            <div>
                <Captcha
                    captchaVisible={captchaVisible}
                    setCaptchaVisible={(v) => {
                        this.setState({ captchaVisible: v });
                    }}
                    onMatch={this.onMatch}
                    getCaptchaInfo={(props) => {
                        this.Captcha = props;
                    }}
                    type="login"
                />

                {/* 首页登录 */}
                {this.props.home ? (
                    <Col span={12} className="tlc-sign">
                        <Modal
                            width="135px"
                            keyboard={false}
                            closable={false}
                            className="Spin_modal"
                            footer={null}
                            visible={this.state.loadinglogin}
                            maskClosable={false}
                        >
                            <Spin tip="กำลังเข้าสู่ระบบ...." />
                            {/* 登录中... */}
                        </Modal>
                        <div className="login-wrap">
                            <div className="forget-password">
                                <Button
                                    type="link"
                                    size="small"
                                    onClick={() => {
                                        this.openForgotPasswordModal();
                                        Pushgtagdata(
                                            "Login",
                                            "Go to Forget Password",
                                            "Login_C_ForgetPassword"
                                        );
                                    }}
                                >
                                    ลืมรหัสผ่าน？ {/* 忘记密码？ */}
                                </Button>
                            </div>
                            <div className="input-wrap">
                                <Input
                                    placeholder="ยูสเซอร์เนม" //用户名
                                    onChange={(e) => {
                                        this.setState({ loginAt: "homepage" });
                                        this.UserInput(e, "USER");
                                    }}
                                    onPressEnter={() => this.HandleEnterKey()}
                                    value={this.state.UserName}
                                    minLength={1}
                                    maxLength={20}
                                />

                                <Input
                                    type="Password"
                                    placeholder="รหัสผ่าน" //密码
                                    onChange={(e) => {
                                        this.setState({ loginAt: "homepage" });
                                        this.UserInput(e, "PWD");
                                    }}
                                    onPressEnter={() => this.HandleEnterKey()}
                                    value={this.state.Password}
                                    minLength={1}
                                    maxLength={20}
                                />
                            </div>
                        </div>
                        <div
                            className="login-btn notice-section-btn offset-small-y"
                            id="SubmitGeet"
                            data-gt
                            onClick={() => {
                                this.setState({ loginAt: "homepage" });
                                this.Login();
                            }}
                        >
                            เข้าสู่ระบบ {/* 登录 */}
                        </div>

                        <div
                            className="register-btn notice-section-btn offset-small-y"
                            onClick={() => {
                                global.goUserSign("2");
                                Pushgtagdata(
                                    "Home",
                                    "Go to Register",
                                    "Home_C_Register"
                                );
                            }}
                        >
                            ลงทะเบียน {/* 注册 */}
                        </div>
                    </Col>
                ) : (
                    /* 弹窗登录 */
                    <Spin
                        spinning={this.state.loadinglogin}
                        tip="กำลังเข้าสู่ระบบ...." //登录中...
                    >
                        <div className="user-modal">
                            {this.state.errorMessage && (
                                <div className="login-error">
                                    {this.state.errorMessage}
                                </div>
                            )}
                            <div className="IputBox">
                                <Input
                                    size="large"
                                    className={
                                        this.state.errorMessage ===
                                            "กรุณากรอกยูสเซอร์เนม​" &&
                                        "hasError"
                                    }
                                    placeholder="ยูสเซอร์เนม" //用戶名
                                    prefix={
                                        <img src="/th/img/icons/user.svg" />
                                    }
                                    onChange={(e) => {
                                        this.setState({ loginAt: "modal" });
                                        this.UserInput(e, "USER");
                                    }}
                                    // onFocus={this.OnBlur}
                                    // onBlur={this.OnBlur}
                                    onPressEnter={() => this.HandleEnterKey()}
                                    minLength={1}
                                    maxLength={20}
                                    value={this.state.UserName}
                                />
                            </div>
                            <div className="IputBox">
                                <Input.Password
                                    type="Password"
                                    size="large"
                                    className={
                                        this.state.errorMessage ===
                                            "กรุณากรอกรหัสผ่าน" && "hasError"
                                    }
                                    placeholder="รหัสผ่าน" //密码
                                    prefix={
                                        <img src="/th/img/icons/password.svg" />
                                    }
                                    onChange={(e) => {
                                        this.setState({ loginAt: "modal" });
                                        this.UserInput(e, "PWD");
                                    }}
                                    // onFocus={this.OnBlur}
                                    // onBlur={this.OnBlur}
                                    onPressEnter={() => this.HandleEnterKey()}
                                    minLength={1}
                                    maxLength={20}
                                    value={this.state.Password}
                                />
                            </div>
                            <div className="link-box">
                                <Button
                                    type="link"
                                    onClick={() => {
                                        this.props.openForgotPwd();
                                        Pushgtagdata(
                                            "Login",
                                            "Go to Forget Password",
                                            "Login_C_ForgetPassword"
                                        );
                                    }}
                                >
                                    ลืมยูสเซอร์เนมหรือรหัสผ่าน？
                                    {/* 忘记用户名或密码？ */}
                                </Button>
                            </div>
                            <Button
                                disabled={
                                    !this.state.userNameInputLengthIsValid ||
                                    !this.state.passwordInputLengthIsValid
                                }
                                size="large"
                                type="primary"
                                block
                                id="SubmitGeetPopUp"
                                data-gt
                                className="btn-cta"
                                onClick={() => {
                                    this.setState({ loginAt: "modal" });
                                    this.Login();
                                }}
                            >
                                เข้าสู่ระบบ{/* 登入 */}
                            </Button>
                        </div>
                    </Spin>
                )}
                <ForgotPwd ref={this.Showmodal} />
                <OpenGame
                    QuickStartGame={true}
                    OnRef={(QuickStartGame) =>
                        (this.QuickStartGame = QuickStartGame)
                    }
                />
            </div>
        );
    }
}
const mapStateToProps = function (state) {
    return {
        cacheGame: state.game.cacheGame,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        setMemberFirstLogin: (isNewMemFirstLogin = false) => {
            dispatch(userCenterActions.setMemberFirstLogin(isNewMemFirstLogin));
        },
        getMemberInfo: (callback, refresh) => {
            dispatch(getMemberInfoAction(callback, refresh));
        },
        setCacheGame: (data) => {
            dispatch(gameActions.setCacheGame(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(_Login);

import React from "react";
import { post } from "$ACTIONS/TlcRequest";
import { ApiPort, APISETS } from "$ACTIONS/TLCAPI";
import { Button, message, Input, Form, Modal, Tabs } from "antd";
import { getQueryVariable } from "$ACTIONS/helper";
import { emailReg, pickNotAlphaNumeric } from "$ACTIONS/reg";
const TabPane = Tabs.TabPane;
message.config({
    top: 100,
    duration: 2,
    maxCount: 1,
});
const regSymbols = /[\s]/;
class ForgotPwd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            Name: "",
            Email: "",
            EmailForForgetUser: "",
            loading: false,
            activeKey: "1",
            errorname: "",
            erroremail: "",
            erroremailForForgetUser: "",
            errorMessage: "",
            errorMessageForForgetUser: "",
            submitstatus: false,
            invalidInput: true,
            invalidInputForForgetUser: true,
        };
        this.MemberChange = this.MemberChange.bind(this);
        this.EmailForgetPassword = this.EmailForgetPassword.bind(this);
        this.Forgetuser = this.Forgetuser.bind(this);
    }
    componentDidMount() {
        const actionParam = getQueryVariable("action");
        actionParam === "forget" && this.showModal(1);
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.visible !== this.state.visible && this.state.visible) {
            if (this.state.activeKey === "1") {
                global.Pushgtagpiwikurl &&
                    global.Pushgtagpiwikurl(
                        "forget_password",
                        "Forget Password"
                    );
            } else if (this.state.activeKey === "2") {
                global.Pushgtagpiwikurl &&
                    global.Pushgtagpiwikurl(
                        "forget_username",
                        "Forget Username"
                    );
            }
        }
        if (prevState.visible !== this.state.visible) {
            this.resetField();
        }
    }

    MemberChange(type, e) {
        if (type == "Name") {
            this.setState(
                { Name: e.target.value.replace(pickNotAlphaNumeric, "") },
                () => {
                    const { Name, Email } = this.state;

                    if (Name.trim().length === 0) {
                        this.setState({
                            errorname: "กรุณากรอกยูสเซอร์เนม", //请填写用户名
                            invalidInput: true,
                        });
                        return;
                    }
                    if (emailReg.test(Email) && Name.trim().length != 0) {
                        this.setState({ invalidInput: false });
                    }
                }
            );
        } else if (type == "Email") {
            this.setState({ Email: e.target.value }, () => {
                const { Email, Name } = this.state;

                if (!emailReg.test(Email) && Email.trim().length > 0) {
                    this.setState({
                        erroremail: "อีเมลไม่ถูกต้อง", //错误电邮格式
                        invalidInput: true,
                    });
                    return;
                }
                if (Email.trim().length === 0) {
                    this.setState({
                        erroremail: "กรุณากรอกอีเมล", //请输入电子邮箱
                        invalidInput: true,
                    });
                    return;
                }

                if (emailReg.test(Email) && Name.trim().length != 0) {
                    this.setState({ invalidInput: false });
                }
            });
        } else if (type == "EmailForForgetUser") {
            this.setState({ EmailForForgetUser: e.target.value }, () => {
                const { EmailForForgetUser } = this.state;

                if (
                    !emailReg.test(EmailForForgetUser) &&
                    EmailForForgetUser.trim().length > 0
                ) {
                    this.setState({
                        erroremailForForgetUser: "อีเมลไม่ถูกต้อง", //错误电邮格式
                        invalidInputForForgetUser: true,
                    });
                    return;
                }
                if (EmailForForgetUser.trim().length === 0) {
                    this.setState({
                        erroremailForForgetUser: "กรุณากรอกอีเมล", //请输入电子邮箱
                        invalidInputForForgetUser: true,
                    });
                    return;
                }

                this.setState({ invalidInputForForgetUser: false });
            });
        }
    }

    showModal = (e) => {
        this.setState({
            visible: true,
            activeKey: String(e),
        });
    };

    handleCancel = (e) => {
        this.setState({
            visible: false,
        });
        //	this.props.pwdModalClose('isShowPwdModal', false);
    };

    EmailForgetPassword() {
        this.setState({
            submitstatus: true,
        });
        let { Email, Name } = this.state;
        Name = Name.trim();
        if (Name == "") {
            this.setState({
                errorname: "กรุณากรอกยูสเซอร์เนม", //请填写用户名
            });
            return;
        }
        if (regSymbols.test(Email)) {
            this.setState({
                erroremail: "ป้อนข้อมูลไม่ถูกต้อง", //邮箱含有非法字符！
            });
            return;
        }
        if (emailReg.test(Email) == false) {
            this.setState({
                erroremail: "อีเมลไม่ถูกต้อง", //错误电邮格式
            });
            return;
        }
        this.setState({
            loading: true,
        });

        let params = { username: Name, email: Email };

        post(`${ApiPort.POSTEmailForgetPassword}`, params)
            .then((res) => {
                console.log("POSTEmailForgetPassword", res);
                if (res?.isSuccess === true) {
                    Modal.info({
                        className: "confirm-modal-of-public forgetPwdModal",
                        width: 400,
                        icon: <></>,
                        title: "ส่งสำเร็จ", //提交成功
                        centered: true,
                        okText: "ปิด", //确认
                        zIndex: 2000,
                        content: (
                            <div
                                style={{ textAlign: "center" }}
                                dangerouslySetInnerHTML={{
                                    __html: "รหัสผ่านถูกส่งไปทางอีเมล\n โปรดตรวจสอบอีเมลที่ลงทะเบียนของคุณ",
                                }} //已将更新密码链接以邮件方式发送, 请至您的注册邮箱查收
                            />
                        ),
                        onOk: () => {
                            this.setState({
                                visible: false,
                            });
                        },
                    });
                } else if (res?.isSuccess === false) {
                    if (res?.errors?.length > 0) {
                        this.setState({
                            errorMessage: res?.errors?.[0]?.description,
                        });
                    } else {
                        this.setState({
                            errorMessage: "โปรดกรอกข้อมูลที่ถูกต้อง",
                        }); //请输入正确的信息
                    }
                }
                Pushgtagdata(
                    "ForgetPassword",
                    "Submit Reset Password",
                    "ForgetPassword_S_ForgotPassword",
                    res?.isSuccess ? 2 : 1,
                    [
                        {
                            customVariableKey: res?.isSuccess
                                ? false
                                : "ForgetPassword_S_ForgotPassword_ErrorMsg",
                            customVariableValue: res?.isSuccess
                                ? false
                                : "โปรดกรอกข้อมูลที่ถูกต้อง",
                        },
                    ]
                );
            })
            .catch((error) => {
                this.setState({
                    errorMessage: error?.errors?.[0]?.description,
                });
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    }

    Forgetuser() {
        this.setState({
            submitstatus: true,
        });
        if (regSymbols.test(this.state.EmailForForgetUser)) {
            this.setState({
                erroremailForForgetUser: "ป้อนข้อมูลไม่ถูกต้อง", //邮箱含有非法字符！
            });
            return;
        }
        if (emailReg.test(this.state.EmailForForgetUser) != true) {
            this.setState({
                erroremailForForgetUser: "อีเมลไม่ถูกต้อง", //错误电邮格式
            });
            return;
        }
        this.setState({
            loading: true,
        });

        post(
            ApiPort.POSTForgetUsername +
                "email=" +
                this.state.EmailForForgetUser +
                "&redirectUrl=" +
                ApiPort.LOCAL_HOST +
                APISETS
        )
            .then((res) => {
                console.log("POSTForgetUsername", res);
                if (res?.isSuccess === true) {
                    Modal.info({
                        className: "confirm-modal-of-forgetuser forgetPwdModal",
                        width: 400,
                        icon: <></>,
                        title: "ส่งสำเร็จ", //提交完成
                        centered: true,
                        okText: "ปิด", //确认
                        closable: true,
                        zIndex: 2000,
                        content: (
                            <div
                                style={{ textAlign: "center" }}
                                dangerouslySetInnerHTML={{
                                    __html: "ยูสเซอร์เนมถูกส่งไปยังอีเมลของคุณเรียบร้อยแล้ว",
                                }} //用户名已成功发送至您的邮箱
                            />
                        ),
                        onOk: () => {
                            this.setState({
                                visible: false,
                            });
                        },
                    });
                } else if (res?.isSuccess === false) {
                    if (res?.errors?.length > 0) {
                        this.setState({
                            errorMessageForForgetUser:
                                res?.errors?.[0]?.description,
                        });
                    } else {
                        this.setState({
                            errorMessageForForgetUser: "อีเมลที่กรอกไม่ถูกต้อง",
                        });
                    }
                }
                Pushgtagdata(
                    "ForgetUsername",
                    "Submit Forget Username",
                    "ForgetUsername_S_Email",
                    res?.isSuccess ? 2 : 1,
                    [
                        {
                            customVariableKey: res?.isSuccess
                                ? false
                                : "ForgetUsername_S_Email_ErrorMsg",
                            customVariableValue: res?.isSuccess
                                ? false
                                : "อีเมลที่กรอกไม่ถูกต้อง",
                        },
                    ]
                );
            })
            .catch((error) => {
                this.setState({
                    errorMessageForForgetUser: error?.errors?.[0]?.description,
                });
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    }

    resetField = () => {
        this.setState({
            Name: "",
            Email: "",
            EmailForForgetUser: "",
            loading: false,
            activeKey: "1",
            errorname: "",
            erroremail: "",
            erroremailForForgetUser: "",
            errorMessage: "",
            errorMessageForForgetUser: "",
            submitstatus: false,
            invalidInput: true,
            invalidInputForForgetUser: true,
        });
    };

    onTabChange(key) {
        this.resetField();
        this.setState({
            activeKey: key,
        });
    }

    render() {
        const {
            loading,
            activeKey,
            visible,
            Name,
            Email,
            EmailForForgetUser,
            errorname,
            erroremail,
            erroremailForForgetUser,
            submitstatus,
        } = this.state;
        return (
            <Modal
                title={activeKey == 1 ? "ลืมรหัสผ่าน" : "ลืมยูสเซอร์เนม"} //忘记密码/忘记用户名
                visible={visible}
                onOk={this.handleCancel}
                onCancel={this.handleCancel}
                destroyOnClose={true}
                footer={null}
                className="modal-pubilc ForgotPwdModal"
                width={400}
                zIndex={2000}
            >
                <Tabs
                    className="Forget-pwd-tabs tabs-modal-pubilc"
                    size="small"
                    tabPosition="top"
                    activeKey={activeKey}
                    onChange={(activeKey) => {
                        this.onTabChange.call(this, activeKey);
                        activeKey === "1"
                            ? Pushgtagdata(
                                  "ForgetUsername",
                                  "Switch to Forget Password",
                                  "ForgetUsername_C_ForgetPassword"
                              )
                            : Pushgtagdata(
                                  "ForgetPassword",
                                  "Go to Forget Username",
                                  "ForgetPassword_C_ForgetName"
                              );
                        global.Pushgtagpiwikurl &&
                            global.Pushgtagpiwikurl(
                                activeKey === "1"
                                    ? "forget_password"
                                    : "forget_username",
                                activeKey === "1"
                                    ? "Forget Password"
                                    : "Forget Username"
                            );
                    }}
                >
                    <TabPane tab="ลืมรหัสผ่าน" key="1">
                        {/* 忘记密码 */}
                        {this.state.errorMessage && (
                            <div className="invalid-input-error">
                                {this.state.errorMessage}
                            </div>
                        )}
                        <div className="IputBox">
                            <div>
                                <label>อีเมลของคุณ</label> {/* 电子邮箱 */}
                                <Input
                                    type="email"
                                    value={Email}
                                    placeholder="กรุณากรอกอีเมล" //请填写邮箱地址
                                    className={
                                        (submitstatus || erroremail) &&
                                        (Email === "" || !emailReg.test(Email))
                                            ? "invalid-input-box-error"
                                            : ""
                                    }
                                    onChange={this.MemberChange.bind(
                                        this,
                                        "Email"
                                    )}
                                    onPressEnter={this.EmailForgetPassword.bind(
                                        this
                                    )}
                                    size="large"
                                />
                                {(submitstatus || erroremail) &&
                                    (Email === "" || !emailReg.test(Email)) && (
                                        <div className="error">
                                            {erroremail}
                                        </div>
                                    )}
                            </div>
                            <div>
                                <label>ยูสเซอร์เนม</label> {/* 用户名 */}
                                <Input
                                    value={Name}
                                    placeholder="กรุณากรอกยูสเซอร์เนม" //请填写用户名
                                    className={
                                        (submitstatus || errorname) &&
                                        Name === ""
                                            ? "invalid-input-box-error"
                                            : ""
                                    }
                                    onChange={this.MemberChange.bind(
                                        this,
                                        "Name"
                                    )}
                                    onPressEnter={this.EmailForgetPassword.bind(
                                        this
                                    )}
                                    size="large"
                                    maxLength={20}
                                />
                                {(submitstatus || errorname) && Name === "" && (
                                    <div className="error">{errorname}</div>
                                )}
                            </div>
                        </div>
                        <Button
                            disabled={this.state.invalidInput}
                            type="primary"
                            size="large"
                            onClick={() => {
                                this.EmailForgetPassword.call(this);
                            }}
                            loading={loading}
                            style={{ width: "100%" }}
                        >
                            ยันยัน {/* 提交 */}
                        </Button>
                    </TabPane>
                    <TabPane tab="ลืมยูสเซอร์เนม" key="2">
                        {/* 忘记用户名 */}
                        {/* <p>请填写您的电子邮箱地址。此邮件地址将被用来发送账户重要信息和申请奖金时所必需的优惠代码。</p> */}
                        {this.state.errorMessageForForgetUser && (
                            <div className="invalid-input-error">
                                {this.state.errorMessageForForgetUser}
                            </div>
                        )}
                        <label>อีเมลของคุณ</label> {/* 电子邮箱 */}
                        <div className="IputBox forgetuser-input">
                            <Input
                                type="email"
                                value={EmailForForgetUser}
                                placeholder="กรุณากรอกอีเมล" //请填写邮箱地址
                                className={
                                    (submitstatus || erroremailForForgetUser) &&
                                    (EmailForForgetUser === "" ||
                                        !emailReg.test(EmailForForgetUser))
                                        ? "invalid-input-box-error"
                                        : ""
                                }
                                onChange={this.MemberChange.bind(
                                    this,
                                    "EmailForForgetUser"
                                )}
                                onPressEnter={this.Forgetuser.bind(this)}
                                size="large"
                            />
                            {(submitstatus || erroremailForForgetUser) &&
                                (EmailForForgetUser === "" ||
                                    !emailReg.test(EmailForForgetUser)) && (
                                    <div className="error">
                                        {erroremailForForgetUser}
                                    </div>
                                )}
                        </div>
                        <Button
                            disabled={this.state.invalidInputForForgetUser}
                            type="primary"
                            size="large"
                            loading={loading}
                            onClick={() => {
                                this.Forgetuser.call(this);
                            }}
                            // className="GreenBtn LoginGreenbtn"
                            style={{ width: "100%" }}
                        >
                            ยันยัน {/* 提交 */}
                        </Button>
                        {/* <Button
							type="default"
							size="large"
							onClick={() => {
								this.setState({
									visible: false
								});
							}}
							style={{ width: '100%', marginTop: '10px' }}
						>
							返回
						</Button> */}
                    </TabPane>
                </Tabs>
            </Modal>
        );
    }
}
export default ForgotPwd;
